import { Route, Navigate, useLocation, Routes } from 'react-router-dom';
import { useEffect } from 'react';

import Login from '../pages/Login';
import Signup from '../pages/Signup';
import Wallet from '../pages/Wallet';
import Settings from '../pages/Settings';
import ChangePassword from '../pages/ChangePassword';
import Banks from '../pages/Banks';
import BlockchainAddress from '../pages/BlockchainAddress';
import Limits from '../pages/Limits';
import IpWhitelist from '../pages/IpWhitelist';
import WithdrawDelay from '../pages/WithdrawDelay';
import About from '../pages/About';
import ApiKeys from '../pages/ApiKeys';
import Subscriptions from '../pages/Subscriptions';
import Activity from '../pages/Activity';
import Mint from '../pages/Mint';
import MintCrypto from '../pages/MintCrypto';
import MintFiat from '../pages/MintFiat';
import Burn from '../pages/Burn';
import AddBank from '../pages/AddBank';
import ChooseBank from '../pages/ChooseBank';
import ChooseNetwork from '../pages/ChooseNetwork';
import BankInformation from '../pages/BankInformation';
import AddWallet from '../pages/AddWallet';
import ForgotPassword from '../pages/ForgotPassword';
import BurnFiat from '../pages/BurnFiat';
import EmailVerified from '../pages/EmailVerified';
import NewPassword from '../pages/NewPassword';
import Intro from '../pages/Intro/Intro';
import Swap from '../pages/Swap/Swap';
import KYCPFLevel1 from '../pages/KYCPFLevel1/KYCPFLevel1';
import KYCPFLevel2 from '../pages/KYCPFLevel2/KYCPFLevel2';
import FormsConfirm from '../pages/KYCPFLevel2/FormsConfirm';
import SwapHistory from '../pages/SwapHistory/SwapHistory';
import PrivateRoute from '../PrivateRoute';
import { useMediaQuery } from '@material-ui/core';
 import HomeMobile from '../pages/Home/HomeMobile';
import Home from '../pages/Home';
import { useTheme } from '@material-ui/core/styles';
import IpadHome from '../pages/Home/IpadHome';
import AboutIpad from '../pages/About/AboutIpad';
import AboutMobile from '../pages/About/AboutMobile';
import BRLAToken from '../pages/About/BRLAToken';
import BRLATokenIpad from '../pages/About/BRLATokenIpad';
import BRLATokenMobile from '../pages/About/BRLATokenMobile';
import KYCPJLevel1 from '../pages/KYCPJLevel1/KYCPJLevel1';
import KYCPJLevel2 from '../pages/KYCPJLevel2/KYCPJLevel2';
import Blog from '../pages/Blog';
import SdkIdwall from '../pages/SdkIdwall/SdkIdwall';
import Payments from '../pages/Payments';
import PixtoUsd from '../pages/PixtoUsd';
import Wise from '../pages/Wise';
import ConfirmWise from '../pages/Wise/Confirm';
import WalletMobile from '../pages/Wallet/WalletMobile';
import { MetaMaskProvider } from 'metamask-react';
import WalletXRP from '../pages/WalletXRP/WalletXRP';



function RoutesApp(){
  const isMobile = useMediaQuery('(max-width:743px)'); // matches if screen width is less than 744px
  const isTablet = useMediaQuery('(min-width:744px) and (max-width:1279px)'); // matches if screen width is between 744px and 1279px
  const isDesktop = useMediaQuery('(min-width:1280px)'); // matches if screen width is greater than 1279px

  let HomeComponent = Home; // default to desktop version
  if (isMobile) {
    HomeComponent = HomeMobile;
  } else if (isTablet) {
    HomeComponent = IpadHome;
  }


  let AboutComponent = About; // default to desktop version
  if (isMobile) {
    AboutComponent = AboutMobile;
  } else if (isTablet) {
    AboutComponent = AboutIpad;
  }
  let BRLATokenComponent = BRLAToken; // default to desktop version
  if (isMobile) {
    BRLATokenComponent = BRLATokenMobile;
  } else if (isTablet) {
    BRLATokenComponent = BRLATokenIpad;
  }

  const matches = useMediaQuery('(min-width:1280px)');
  return(
    <Routes>
         <Route path="/signup" element={<Signup />} />
         <Route path="/" element={matches ? <Login /> : <Login />} />
    <Route path="/about-brla-digital" element={<AboutComponent />} />
    <Route path="/about-brla-token" element={<BRLATokenComponent />} />
    <Route path="/intro" element={<Intro />} />
    <Route path="/login" element={matches ? <Login /> : <Login />} />
    <Route path="/wallet" element={matches ? <Wallet /> : <WalletMobile />} />
      <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
      <Route path="/change-password" element={<PrivateRoute><ChangePassword /></PrivateRoute>} />
      <Route path="/banks" element={<PrivateRoute><Banks /></PrivateRoute>} />
      <Route path="/blockchain-address" element={<PrivateRoute><BlockchainAddress /></PrivateRoute>} />
      <Route path="/limits" element={<PrivateRoute><Limits /></PrivateRoute>} />
      <Route path="/withdraw-delay" element={<PrivateRoute><WithdrawDelay /></PrivateRoute>} />
      <Route path="/api-keys" element={<PrivateRoute><ApiKeys /></PrivateRoute>} />
      <Route path="/subscriptions" element={<PrivateRoute><Subscriptions /></PrivateRoute>} />
      <Route path="/activity" element={<PrivateRoute><Activity /></PrivateRoute>} />
      <Route path="/mintfiat" element={<PrivateRoute><MintFiat /></PrivateRoute>} />
      <Route path="/bankinformation" element={<PrivateRoute><BankInformation /></PrivateRoute>} />
      <Route path="/addwallet" element={<PrivateRoute><AddWallet /></PrivateRoute>} />
      <Route path="/burn-fiat" element={<PrivateRoute><BurnFiat /></PrivateRoute>} />
      <Route path="/change_password" element={<NewPassword />} />
      <Route path="/kyc-pf-level-1" element={<PrivateRoute><KYCPFLevel1 /></PrivateRoute>} />
      <Route path="/kyc-pf-level-2" element={<PrivateRoute><KYCPFLevel2 /></PrivateRoute>} />
      <Route path="/kyc-pj-level-1" element={<PrivateRoute><KYCPJLevel1 /></PrivateRoute>} />
      <Route path="/kyc-pj-level-2" element={<PrivateRoute><KYCPJLevel2 /></PrivateRoute>} />
      <Route path="/swap-history" element={<PrivateRoute><SwapHistory /></PrivateRoute>} />
      <Route path="/payments" element={<PrivateRoute><Payments /></PrivateRoute>} /> 
      <Route path="/xrp" element={<PrivateRoute><WalletXRP /></PrivateRoute>} />
      <Route path="/swap" element={<PrivateRoute><Swap /></PrivateRoute>} />
      <Route path="/pix-to-usd" element={<PrivateRoute><PixtoUsd /></PrivateRoute>} />
      <Route path="/sdk-idwall" element={<SdkIdwall />} /> 
      <Route path="/wise" element={<Wise />} /> 
      <Route path="/confirm-wise" element={<ConfirmWise />} /> 
  {/*    <Route path="/blog" element={<Blog />} />*/}
  {/*<Route path="/choosebank" element={<ChooseBank />} />*/}
  <Route path="/forgot-password" element={<ForgotPassword />} />
  <Route path="/email_verified" element={<EmailVerified />} />
  <Route path="/confirm-info" element={<FormsConfirm />} />
  

  {/*<Route path="/choosenetwork" element={<ChooseNetwork />} />
  <Route path="/mint" element={<Mint />} />
  <Route path="/mintcrypto" element={<MintCrypto />} />
  <Route path="/ip-whitelist" element={<IpWhitelist />} />
  <Route path="/addbank" element={<AddBank />} />
  <Route path="/burn" element={<Burn />} />*/}

  
    
  

  </Routes>
  
  )
}

export default RoutesApp;