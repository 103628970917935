import SettingsHeader from "../../Components/SettingsHeader";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ENDPOINT, MANTEINENCETOKEN} from '../../variaveis';
import FooterLogged from "../../Components/FooterLogged";
import classes from './SETTINGSPERSONALINFO.module.css';
import resets from './_resets.module.css';
import QRCode from 'qrcode.react';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useTranslation } from 'react-i18next';



function Settings() {
  const { t, i18n} = useTranslation();
  const [codeoneActive, SetcodeoneActive] = useState(false);
  const [codetwoActive, SetcodetwoActive] = useState(false);
  const [codetreeActive, SetcodetreeActive] = useState(false);
  const [codefourActive, SetcodefourActive] = useState(false);
  const [codefiveActive, SetcodefiveActive] = useState(false);
  const [codesixActive, SetcodesixActive] = useState(false);
  const [showSecret, SetshowSecret] = useState(false);
 
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid },
    reset,
    setValue,
    setFocus,
    watch, // Adicione esta linha
  } = useForm({ mode: 'onChange' });


  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pendingRequests, setPendingRequests] = useState(1);
  const [kychistoryData, setKycHistoryData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [completed, setCompleted] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      const lastRequestDate = localStorage.getItem('lastRequestDate');
      const currentDate = new Date();
  
      if (lastRequestDate && currentDate - new Date(lastRequestDate) < 10000) {
        // If less than a minute has passed since the last request, do not make a new request
        return;
      }
  
      const resUser = await fetch(`${ENDPOINT}/info`, {
        method: 'GET',
        credentials: 'include',
      });
      const bodyUser = await resUser.json();
      localStorage.setItem('userData', JSON.stringify(bodyUser));
      const userKycLevel = bodyUser?.kyc?.level;
      if (userKycLevel > 0) {
        const resUsedLimit = await fetch(`${ENDPOINT}/used-limit`, {
          method: 'GET',
          credentials: 'include',
        });
        const bodyUsedLimit = await resUsedLimit.json();
        localStorage.setItem('usedLimit', JSON.stringify(bodyUsedLimit));
  
        
      }
  
      const reskychistory = await fetch(`${ENDPOINT}/kyc/history`, {
        method: 'GET',
        credentials: 'include',
      });
      const bodykychistory = await reskychistory.json();
      localStorage.setItem('kychistory', JSON.stringify(bodykychistory.history || []));
  
      localStorage.setItem('nextRequestDate', currentDate.toISOString());
  
      // After making the requests, store the current date as the last request date
      localStorage.setItem('lastRequestDate', currentDate.toISOString());
    };
  
    loadData();
  }, []);

  useEffect(() => {
    // Obtem os usuários do localStorage
    const loadedUsers = localStorage.getItem('userData');
    if (loadedUsers) {
      try {
        const parsedUsers = JSON.parse(loadedUsers);
        setUsers(parsedUsers);
      } catch (error) {
        console.error('Error parsing userData:', error);
      }
    }
  
    const loadedKYCHistory = localStorage.getItem('kychistory');
    if (loadedKYCHistory) {
      try {
        const parsedKYCHistory = JSON.parse(loadedKYCHistory);
        // Criando um objeto onde a chave é o ID e o valor é o item do histórico
        setKycHistoryData(parsedKYCHistory);
      } catch (error) {
        console.error('Error parsing kychistory:', error);
      }
    }
  
    setPendingRequests(0);
  }, []);
  






  const levelLabels = {
    0: { status: 'No Verification', verification: 'Not Verified' },
    1: { status: 'Basic Verification', verification: 'Verified' },
    2: { status: 'Intermediary Verification', verification: 'Verified' },
    3: { status: 'Advanced Verification', verification: 'Verified' },
  };
  

  
  function formatDate(dateString) {
    // Cria um objeto Date a partir da string
    let date = new Date(dateString);
  
    // Ajusta para o fuso horário local
    date = new Date(date.getTime() );
  
    // Extrai os componentes da data
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
  
    // Mapeia os números dos meses para os nomes dos meses
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthName = monthNames[month];
  
    // Retorna a data formatada
    return `${monthName} ${day}, ${year}`;
  }



  function formatTime(dateString) {
    // Cria um objeto Date a partir da string
    let date = new Date(dateString);
  
    // Ajusta para o fuso horário local
    date = new Date(date.getTime() );
  
    // Extrai as componentes de hora e minuto
    let hour = date.getHours();
    const minute = date.getMinutes();
  
    // Converte a hora para o formato 12 horas
    let period = 'AM';
    if (hour >= 12) {
      period = 'PM';
      if (hour > 12) hour -= 12;
    } else if (hour === 0) {
      hour = 12;
    }
  
    // Retorna a hora formatada
    return `${hour}:${minute < 10 ? '0' : ''}${minute} ${period}`;
  }


  
  const handleClosePopup = () => {
    setShowPopup(false);
  }


  const onSubmit = async (data) => {
    
    setIsLoading(true); // Definir como loading quando a requisição começar
    const response = await fetch(`${ENDPOINT}/2fa/validate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "MAINTENANCE-TOKEN": MANTEINENCETOKEN
      },
      credentials:'include',
      body: JSON.stringify({otp: data.code_1 + data.code_2 + data.code_3 + data.code_4 + data.code_5 + data.code_6}),
    });
  
    if (response.status === 200 || response.status === 201) {
      toast.success('2FA successfully created!'); // Concatene a mensagem de erro
      setIsLoading(false);
      setShowPopup(false);
      const responseData = await response.json(); 
      setResponseData(responseData);
     


const resUser = await fetch(`${ENDPOINT}/info`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          "MAINTENANCE-TOKEN":MANTEINENCETOKEN
        },
      });
      const bodyUser = await resUser.json();
      localStorage.setItem('userData', JSON.stringify(bodyUser));

      setCompleted(true);
    } else {
      const responseData = await response.json();
      toast.error('Error: ' + responseData.error); // Concatene a mensagem de erro
      setIsLoading(false);
    };

    setIsLoading(false)
  };
  

  const showseecretfunction = () => {

    SetshowSecret(!showSecret);

 


   
  }
  
  
  const FShowPopup = async () => {


    const response = await fetch(`${ENDPOINT}/2fa/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "MAINTENANCE-TOKEN":MANTEINENCETOKEN
      },
      credentials:'include'
      
    });
  
    
    if (response.status === 200 || response.status === 201) {

      const responseData = await response.json(); 
      setResponseData(responseData);
      setShowPopup(!showPopup)



    } else {
      
      const  responseData = await response.json();
      
      toast.error('Error: ' + responseData.error); 
  
     
    }
    
  };

   
  


    return (
<>
  <div id="__next" style={{overflowX:'hidden'}}>
  {showPopup  && (
 <div className="wallet-popup-overlay">
 <div className="wallet-popup" style={{position: 'relative'}}>
   <button style={{position: 'absolute', top: 0, right: 0, fontSize: '1.5em'}} className="close-popup limpar-botao" onClick={handleClosePopup}>X</button>
   {
completed ? <p className="warning" style={{color:'black', textAlign:'center'}}>2FA habilitado com sucesso</p> :

    <>
   <h1 style={{color:'black', fontSize:'24px'}}>Fator de multi autenticação</h1>
   <p className="warning" style={{display:"flex", justifyContent:'center', textAlign:'justify', color:"black"}}>
    
   Você precisará de um aplicativo de autenticação em dois fatores para completar o processo. Escaneie o código QR com o seu aplicativo.
   <br/>
   <br/>
   </p>

   
   <QRCode
   style={{margin:'0 auto'}}
 value={responseData?.qrCode}
 size={200}
 bgColor="transparent"
 fgColor="#000000"
 renderAs="svg"
 imageSettings={{
   src: "https://brla.digital/static/media/logo_b.png",
   x: null,
   y: null,
   height: 24,
   width: 24,
   excavate: true,
 }}
/>


<a onClick={showseecretfunction} style={{color:'#00dc84'}}>Adicionar secret manualmente</a>
{showSecret && (<p className="warning" style={{color:'black', textAlign:'center'}}>{responseData?.secret}</p>)}

<p className="warning" style={{color:'black'}}>Insira o código de autenticação de 6 dígitos gerado pelo seu aplicativo de autenticação</p>

   <br/>

   <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="index_gutter__a2hta" />
                  <div className="verify-input-container">
                    <div className="code-input-container">
                    <div className={`code-section${codeoneActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_2');
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                          }}
                          onFocus={() => {
                            SetcodeoneActive(true);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_1', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codetwoActive ? ' active' : ''}`}>

                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_3');
                            SetcodetreeActive(true);
                            SetcodetwoActive(false);
                          }}
                          onFocus={() => {
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_2', { required: true })}
                        />

                      </div>
                      <div className={`code-section${codetreeActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_4');
                            SetcodefourActive(true);
                            SetcodetreeActive(false);
                          }}
                          onFocus={() => {
                            SetcodetreeActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_3', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefourActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_5');
                            SetcodefiveActive(true);
                            SetcodefourActive(false);
                          }}
                          onFocus={() => {
                            SetcodefourActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_4', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefiveActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_6');
                            SetcodesixActive(true);
                            SetcodefiveActive(false);
                          }}
                          onFocus={() => {
                            SetcodefiveActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_5', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codesixActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onFocus={() => {
                            SetcodesixActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                          }}
                          {...register('code_6', { required: true })}
                        />
                      </div>
                    </div>
                  </div>
                

                  <div className="index_gutter__a2hta" />
                 

   <button type="submit"  disabled={isLoading || !isValid} className="cb-button base primary">
   {isLoading ? "Carregando..." : "Confirmar"}
   </button>
                  </form>
                  </>
                  }

 </div>
</div>
)}
    <div className="grid w-full min-h-screen font-circular grid min-h-screen w-full">
      <SettingsHeader/>
      <main className=" flex flex-col bg-surface-background">
        <div className="grid flex-1 content-start gap-y-12 py-12 main-container">
          <div className="px-16">
            <div className="mx-auto max-w-7xl space-y-7 min-w-4xl">
              <header className="cb-header">
                <div className="cb-header-head primary">
                  <h1 className="title">{t('profile')}</h1>
                  <div className="actions" />
                </div>
               
              </header>
              <div style={{marginLeft:'-20px'}} className={classes.frame25}>
          <div className={classes.section}>
            <div className={classes.frame6}>
              <div style={{display:"flex"}} className={classes.frame2}>
                <div className={classes.name}>{t('name')}:</div>
                <div style={{marginLeft:'60px'}} className={classes.matheusHenriqueAlvesMoura}>{users?.fullName}</div>
              </div>
              <div style={{display:"flex"}} className={classes.frame5}>
                <div className={classes.eMail}>E-mail:</div>
                <div style={{marginLeft:'60px'}} className={classes.mmouraBrlaDigital}>{users?.email}</div>
              </div>
              <div style={{display:"flex"}} className={classes.frame62}>
                <div className={classes.password}>
                  <div   className={classes.textBlock}>
                  {t('password')}:
                  </div>
                </div>
                <div style={{marginLeft:'60px'}} className={classes.LastChangeMay251022AM}>
                  <div  className={classes.textBlock2}>********************</div>
                  <div className={classes.textBlock3}>
                    <p className={classes.labelWrapper}>
                      <span className={classes.label}>Última mudança: {formatDate(users?.passwordChangedAt)}, {formatTime(users?.passwordChangedAt)}</span>
                    </p>
                  </div>
                </div>
                <a href="/change-password" className={classes.changePassword}>{t('change_password')}</a>
              </div>
              <div style={{display:'flex'}} className={classes.frame3}>
                <div className={classes.verification}>{t('verification')}:</div>
                <div style={{marginLeft:'23px'}} className={classes.basicVerification}>
  {users?.kyc?.level < 2 ? 'Verificação básica' : 'Verificação intermediária'}
</div>

                {users?.kyc?.level < 2 && (
                  <a onClick={FShowPopup} style={{color:'#00dc84'}}  className={`${classes.increaseYourLimits}`}>{t('enable_2fa')}</a>

)}

              </div>
            <div style={{display:'flex'}} className={classes.frame72}>
                <div className={classes.mainAsset}>{t('main_asset')}:</div>
                <div style={{marginLeft:'0px'}} className={classes.rBRL}>R$ BRL</div>
                </div>
            </div>
          </div>
        </div>
              <div
                className="cb-card surface_primary shadow_md"
                data-testid="active-factor"
              >
              {/*    <div className="content flex items-center">
                  <div className="flex min-w-0 flex-1 items-center">
                    <div
                      className="cb-icon-circle bg-primary text-icon-primary"
                      data-testid="icon-circle-devicemobile"
                      style={{ width: 50, height: 50 }}
                    >
                      <svg
                        width={25}
                        height={25}
                        viewBox="0 0 10 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cb-icon cb-icon-devicemobile pointer-events-none"
                        aria-hidden="true"
                        role="img"
                        focusable="false"
                        data-testid="icon-devicemobile"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Zm3 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                        />
                      </svg>
                    </div>
                  <div className="grid min-w-0 flex-1 grid-rows-2 gap-1 px-4">
                      <h2 className="text-lg text-black-600 font-circular-bold">
                        Authenticator app
                      </h2>
                      <p className="text-sm text-black-600 font-circular-regular">
                        Added: Mar 16, 10:26 AM
                      </p>
    </div> 
                  </div>
                </div> */ }
              </div>
            </div>
          </div>
        </div>
        <FooterLogged/>
      </main>
    </div>
  </div>
  <ToastContainer />
</>)};
export default Settings;
