
import WalletHeader from '../../Components/WalletHeader';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import './css/styles.css';
import './css/226085e3c6877b32.css';
import ferramentaImage from '../Home/images/ferramenta2.png';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { ENDPOINT, BRLA_CONTRACT_ADDRESSES, USDC_CONTRACT_ADDRESSES, USDT_CONTRACT_ADDRESSES, CRYPTO_FRIENDLY, POLYGONURL} from '../../variaveis';
import FooterLogged from '../../Components/FooterLogged';
import { BRLAContractAbi, USDCContractAbi,USDTContractAbi  } from '../../utils/abis';
import Web3 from 'web3';
import { Buffer } from "buffer/";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import AddBank from '../AddBank';
import MetamaskIcon from './MetaMask_Fox.svg'
import BRLAIcon from '../Swap/logo_brla.png';
import UsdcIcon from '../Swap/usdc_symbol.png'
import Deposit from '../../Components/Deposit';
import Transfer from '../../Components/Transfer';
import Transactions from '../../Components/Transactions';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import Brazil from './brazil.png';
import Swap from './swap.png'
import SwapBa from '../../Components/Swap';
import { useTranslation } from 'react-i18next';
import brazilFlag from '../Home/images/brazil.png'; // import your flag images
import usaFlag from '../Home/images/usa.png';
import {isInstalled , getAddress, setTrustline } from "@gemwallet/api";
const responsiveStyle = {
  width: '100%',
  maxWidth: '100%',
  overflowX: 'hidden',
};
function Wallet() {
  
  const [tooltip, setTooltip] = React.useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pendingRequests, setPendingRequests] = useState(1);
  const [users, setUsers] = useState([]);
  const [balance, setBalance] = useState([]);
  const [balanceUsd, setBalanceUsd] = useState([]);
  const [balanceUsdt, setBalanceUsdt] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [kychistoryData, setKycHistoryData] = useState([]);
  const [showDeposit, setShowDeposit] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [showSwap, setShowSwap] = useState(false);
  const buttonRefDeposit = useRef(null);
  const buttonRefTransfer = useRef(null);
  const { t, i18n} = useTranslation();
  const buttonRefSwap = useRef(null);
  const [Token, setToken] = useState('TOKEN');
  const [Connected, setisConnected] = useState(false)
  const [AddressGlobal, setAddressGlobal] = useState('')
  useEffect(() => {
    // Obtem os usuários do localStorage
    const loadedUsers = localStorage.getItem('userData');
    if (loadedUsers) {
      setUsers(JSON.parse(loadedUsers));
    }


    // Obtem os dados do histórico do localStorage
    const loadedHistoryData = localStorage.getItem('historyData');
    if (loadedHistoryData) {
      let historyData = JSON.parse(loadedHistoryData);
    
      // Ordenando os dados do histórico pela data de atualização
      historyData = historyData.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    
      // Criando um objeto onde a chave é o ID e o valor é o item do histórico
      setHistoryData(historyData);
      console.log("vamos ver: ", historyData)
    };


    const loadedKYCHistory = localStorage.getItem('kychistory');
    if (loadedKYCHistory) {
      const KycHistory = JSON.parse(loadedKYCHistory);
      console.log("VAI: ", KycHistory)
      // Criando um objeto onde a chave é o ID e o valor é o item do histórico
      setKycHistoryData(KycHistory);


    };



    const fetchBalance = async () => {
    
   
      // Cria uma nova instância do web3 com o Infura
      const web3 = new Web3(new Web3.providers.HttpProvider(POLYGONURL));

      // Mapeia cada carteira para um objeto que inclui o saldo BRLA
      
          const BRLA_CONTRACT_ADDRESS = BRLA_CONTRACT_ADDRESSES[137];
          const USDC_CONTRACT_ADDRESS = USDC_CONTRACT_ADDRESSES[137];
          const USDT_CONTRACT_ADDRESS = USDT_CONTRACT_ADDRESSES[137];
          const contract = new web3.eth.Contract(BRLAContractAbi, BRLA_CONTRACT_ADDRESS);
          const contractUsd = new web3.eth.Contract(USDCContractAbi, USDC_CONTRACT_ADDRESS);
          const contractUsdt = new web3.eth.Contract(USDTContractAbi, USDT_CONTRACT_ADDRESS);
          const balance = await contract.methods.balanceOf(users.walletAddress).call();
          const balanceUsd = await contractUsd.methods.balanceOf(users.walletAddress).call();
          const balanceUsdt = await contractUsdt.methods.balanceOf(users.walletAddress).call();
          setBalance(balance)
          setBalanceUsd(balanceUsd);
          setBalanceUsdt(balanceUsdt);

      
      

      
  };

  if (users.walletAddress) {
    fetchBalance();
  }
  setPendingRequests(0);

  }, [users.walletAddress]);

  function numberWithThousandSeparator(number) {
    return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

 





  function formatDate(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  
  
  
  const navigate = useNavigate();
  
   

      useEffect(() => {
   
    }, []);
    const depositRef = useRef();
    const transferRef = useRef();
    const swapRef = useRef();

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (showDeposit && depositRef.current && !depositRef.current.contains(event.target) && !buttonRefDeposit.current.contains(event.target)) {
          setShowDeposit(false);
        }
      };
    
      document.addEventListener('click', handleClickOutside);
    
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [showDeposit]);
    
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (showTransfer && transferRef.current && !transferRef.current.contains(event.target) && !buttonRefTransfer.current.contains(event.target)) {
          setShowTransfer(false);
          console.log("VAI")
        }
      };
    
      document.addEventListener('click', handleClickOutside);
    
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [showTransfer]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (showSwap && swapRef.current && !swapRef.current.contains(event.target) && !buttonRefSwap.current.contains(event.target)) {
          setShowSwap(false);
         
        }
      };
    
      document.addEventListener('click', handleClickOutside);
    
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [showSwap]);
    

    useEffect(() => {
      // Se não há requisições pendentes, então as requisições maamacabaram
      if (pendingRequests === 0) {
        // Adiciona um delay de 0.2 segundos antes de remover o estado de carregamento
        setIsLoading(false);
      } 
    }, [pendingRequests]);

    
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };

    if (isLoading) {
      // Retorne um spinner de loading, um placeholder ou null
      return <div></div>;
    }


    const statuses = ['Pendente', 'Completo', 'Cancelada'];




    const handleConnect = () => {
      console.log("Teste")
      isInstalled().then((isInstalled) => {
        if (isInstalled) {
          getAddress().then((response) => {
            console.log(`Your address: ${response.result?.address}`);
            console.log(response.type);
            setAddressGlobal(response.result?.address)
            setisConnected(true);
            toast.success(t('wallet_success'))

            console.log()
          });
          
        }

        else  {
          toast.error(t("install_gemwallet"))
        }
      });
    };


    const handleTrusline = () => {
      isInstalled().then((response) => {
        if (isInstalled) {
          const transaction = {
            limitAmount: {
              currency: "BRL",
              issuer: "rNWrQWYxqYj4Rz5RNrJxHeEMTwZrNtz9vy",
              value: "100000000000",
            },
            flags: {tfSetNoRipple:true}
          };
          setTrustline(transaction).then((response) => {
            console.log("Transaction Hash: ", response.result?.hash);
          });
        }
      });
    };
  
  
  


return (
  
<div  id="__next" style={{overflowX:'hidden'}}>
  
  <div >
    <div
      style={{ backgroundColor: "#08383f !important" }}
      className="grid w-full min-h-screen font-circular"
    >
      <WalletHeader/>
      <main className="flex-grow flex flex-col bg-surface-background">
        <div className="grid flex-1 gap-y-12 content-start">
          <div data-testid="payments-page-header" className="px-16 mt-12">
            <div className="mx-auto max-w-7xl space-y-7 min-w-4xl">
              <header  className="cb-header " >
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>   
                <h1 style={{ color: "#08383f", fontSize:'70px' }} className="title  margin-titulo">
                  {t('welcome')}, {users?.fullName.split(' ')[0]}
                   </h1>  

     <div style={{display:'flex', justifyContent:'center', marginTop:'40px', marginLeft:"140px"}}>          
<button
  ref={buttonRefTransfer}
  style={{ backgroundColor: "#08383f", marginRight: "10px" }}
  type="button"
  className="cb-button base primary"
  data-testid="account-funding-button"
  onClick={handleTrusline}
>
  <span>Trust line</span>
</button>
    
    
               
                    
    <button
  ref={buttonRefDeposit}
  style={{ backgroundColor: "#08383f", marginLeft: "10px" }}
  type="button"
  className="cb-button base primary"
  data-testid="account-funding-button"
  onClick={handleConnect}
>
  <span>{t('connect_wallet')}</span>
</button>
    

<button
  ref={buttonRefDeposit}
  style={{ backgroundColor: "#08383f", marginLeft: "10px" }}
  type="button"
  className="cb-button base primary"
  data-testid="account-funding-button"
  onClick={() => {
    setShowDeposit(!showDeposit);
  }}
  disabled={AddressGlobal===''}
>
  <span>Mint</span>
</button>

<button
  ref={buttonRefTransfer}
  style={{ backgroundColor: "#08383f", marginLeft: "10px" }}
  type="button"
  className="cb-button base primary"
  data-testid="account-funding-button"
  onClick={() => {
    setShowTransfer(!showTransfer);
  }}
  disabled={AddressGlobal===''}
>
  <span>Burn</span>
</button>
    
</div>

</div>
         

                {showDeposit && <div ref={depositRef} className="overlay"></div>}
{showTransfer && <div ref={transferRef} className="overlay"></div>}
{showSwap && <div ref={swapRef} className="overlay"></div>}
{showDeposit && <Deposit ref={depositRef} addressGlobal={AddressGlobal} setAddressGlobal={setAddressGlobal} />}    
{showTransfer && <Transfer ref={transferRef} />}   
{showSwap && <SwapBa ref={swapRef} />}   

                           
              </header>
            </div>
          </div>
      

        
        </div>
  <FooterLogged/>
      </main>
    </div>
  </div>
  <ToastContainer />
  <Tooltip id="my-tooltip" />
</div>)}
export default Wallet;
