import { memo, useState, useEffect, forwardRef } from 'react';

import { ENDPOINT, MANTEINENCETOKEN, USDC_CONTRACT_ADDRESSES} from '../../variaveis';

import resets from './_resets.module.css';
import classes from './Section.module.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import QRCode from 'qrcode.react';
import LogoB from './logo_b.png';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useTranslation } from 'react-i18next';
const Deposit = forwardRef((props, ref) => {
  const [bgColor, setBgColor] = useState('#ffffff');
const [qrColor, setQrColor] = useState('none');
const [users, setUsers] = useState([]);
const [isLoading, setIsLoading] = useState(true);
const [pendingRequests, setPendingRequests] = useState(1);
const [kychistoryData, setKycHistoryData] = useState([]);
const [currencyType, setcurrencyType] = useState('');
const { t, i18n} = useTranslation();
const [Amount, setAmount] = useState(0)
const [showQRCode, setShowQRCode] = useState(false)
const {addressGlobal, setAddressGlobal} = props;
const [brCode, setBrCode] = useState('')
useEffect(() => {
  const loadData = async () => {
    const lastRequestDate = localStorage.getItem('lastRequestDate');
    const currentDate = new Date();

    if (lastRequestDate && currentDate - new Date(lastRequestDate) < 10000) {
      // If less than a minute has passed since the last request, do not make a new request
      return;
    }

    const resUser = await fetch(`${ENDPOINT}/info`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        "MAINTENANCE-TOKEN":MANTEINENCETOKEN
      },
    });
    const bodyUser = await resUser.json();
    localStorage.setItem('userData', JSON.stringify(bodyUser));

   
    localStorage.setItem('nextRequestDate', currentDate.toISOString());

    // After making the requests, store the current date as the last request date
    localStorage.setItem('lastRequestDate', currentDate.toISOString());
  };

  loadData();
}, []);

useEffect(() => {
  // Obtem os usuários do localStorage
  const loadedUsers = localStorage.getItem('userData');
  if (loadedUsers) {
    setUsers(JSON.parse(loadedUsers));
  }

  const loadedKYCHistory = localStorage.getItem('kychistory');
  if (loadedKYCHistory) {
    const KycHistory = JSON.parse(loadedKYCHistory);
    // Criando um objeto onde a chave é o ID e o valor é o item do histórico
    setKycHistoryData(KycHistory);


  };



  setPendingRequests(0);
}, []);

const handleCopy = () => {
  toast.success('Chave PIX copiada com sucesso!');
};

const handleCopyWallet = () => {
  toast.success('Endereço da wallet copiado com sucesso!');
};

const ClickUsa = (e) => {
  e.stopPropagation(); // Impede a propagação do evento de clique
  setcurrencyType('USA');
  
}

const ClickBrazil = (e) => {
  e.stopPropagation(); // Impede a propagação do evento de clique
  setcurrencyType('Brazil');
  
}

const handlePixKeyChange = (event) => {
  const inputValue = event.target.value;
  
  

  setAmount(inputValue);
};


async function mintDexToken() {
  const url = `${ENDPOINT}/dex/mint`;
  const body = {
      amount: parseFloat(Amount)*100,
      walletAddress: addressGlobal,
      chain: 'XRP'
  };

  try {
      const response = await fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          credentials: 'include', // Include credentials in the request
          body: JSON.stringify(body)
      });

      const responseData = await response.json();


      if (!response.ok) {
        toast.error(`Error: ${responseData.error}`) 
        throw new Error(`Error: ${response.status}`);
         
      }
      else {
       setShowQRCode(true)
      }

      setBrCode(responseData.brCode)
      return responseData;
  } catch (error) {
      console.error('Error making the request:', error);
  }
}

  return (
    <div ref={ref} className={`${resets.storybrainResets} ${classes.root} main-container-11 `}>

      
      <div className={classes.frame15}>
        <div className={classes.frame6}>
          <div className={classes.frame2}>
            <div className={classes.pIXQRCode}>{showQRCode ? 'QR Code PIX':  'Mint'}</div>
            
  {  !showQRCode && <form style={{display:'flex', flexDirection:'column'}}>
    <div style={{display:'flex', flexDirection:'row'}}>
   <label style={{color:'white', marginRight:'5px'}}>Amount:</label>
    <input onChange={handlePixKeyChange} style={{background:'white'}}/>
    </div>
    <button onClick={mintDexToken} type='button' style={{margin:'0 auto', marginTop:'10px', background:"#00dc84", color:"white", borderRadius:"10px", padding:"10px"}}>Confirm</button>
    </form> }
       
 { showQRCode && <QRCode
    value={brCode}
    size={200}
    bgColor="transparent"
    fgColor="#ffffff"
    renderAs="svg"
    imageSettings={{
      src: "https://brla.digital/static/media/logo_b.png",
      x: null,
      y: null,
      height: 24,
      width: 24,
      excavate: true,
    }}
  />}

  <div style={{textAlign:'center'}} className={classes.frame16}>

  </div>
  <div className={classes.checkOurComplianceAMLAndCFTPol}>
    <p style={{marginLeft:'10px'}} className={classes.labelWrapper}>
      
    </p>
  </div>


        
           
          </div>
     
        </div>
      </div>
      <ToastContainer />
    </div>
  );
});
export default Deposit
