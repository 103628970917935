import { useState } from 'react';

import resets from '../Home/_resets.module.css';
import { CarbonMenuIcon } from '../Home/CarbonMenuIcon.js';
import { FrameIcon2 } from '../Home/FrameIcon2.js';
import { FrameIcon } from '../Home/FrameIcon.js';
import classes from './MobileBRLATokenAbout.module.css';
import { useTranslation } from 'react-i18next';
import brazilFlag from '../Home/images/brazil.png'; // import your flag images
import usaFlag from '../Home/images/usa.png';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

declare var grecaptcha: any;

function BRLATokenMobile() {
  const { t, i18n} = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };
    const [visibleMemberId, setVisibleMemberId] = useState(null);

    const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');

  function onSubmit(e) {
    e.preventDefault();
  
    grecaptcha.ready(function() {
      grecaptcha.execute('6LdIKIQmAAAAABNZn-t5ilym4wSfgsAkLuzBtn00', {action: 'submit'}).then(function(token) {
        const data = {
          name: name,
          email: email,
          phone: phone,
          company: company,
          message: message,
          captcha: token
        };
  
        fetch('https://api.brla.digital:5567/v1/contact-us', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
        .then(response => {
          if (response.ok) {
            toast.success('Message sent successfully. We will respond soon.', {
              autoClose: 6000
            });
            setTimeout(() => window.location.reload(), 7000);
          } else {
            throw new Error('Failed to send message');
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error('Failed to send message. Please try again.', {
            autoClose: 6000
          });
          setTimeout(() => window.location.reload(), 7000);
        });
      });
    });
  }



  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <div className={classes.navigation}>
        <div className={classes.frame4}>
          <div className={classes.logo}></div>
        </div>
        <div onClick={toggleMenu} className={classes.carbonMenu}>
          <CarbonMenuIcon  style={{height:'50px', width:"50px"}} />
          <ul style={{marginTop:'40px', marginLeft:'0px', marginRight:'0px' , position:'absolute', background:'#008884'}}
          className={`main-navigation d-none d-lg-flex ${
            menuOpen ? 'd-flex' : 'd-none'
          }`}
        >
          {/* Adicione aqui os itens de menu */}
          <li className="menu-list">
            <a style={{fontWeight:'bolder'}} className="menu-link" href="/">
              HOME
            </a>
            </li>
            <li className="menu-list">
            <a style={{fontWeight:'bolder'}} className="menu-link" href="/about-brla-digital">
            {t('menu_about')} BRLA DIGITAL
            </a>
            </li>
            <li className="menu-list">
            <a style={{fontWeight:'bolder'}} className="menu-link" href="/about-brla-token">
            {t('menu_about')} BRLA TOKEN
            </a>
            </li>
            <li className="menu-list">
            <a style={{fontWeight:'bolder'}} className="menu-link" href="/login">
              LOGIN
            </a>
            </li>
            <li className="menu-list">
            <a style={{fontWeight:'bolder'}} className="menu-link" href="/signup">
              {t('menu_sign')}
            </a>
            </li>
            <li className="menu-list">
            <a style={{fontWeight:'bolder'}} className="menu-link" href="https://brla-superuser-api.readme.io/reference/welcome" target="_blank">
              {t('menu_api')}
            </a>
            </li>
            <li className="menu-list">
            <a style={{fontWeight:'bolder'}} className="menu-link" href="#contact" target="_blank">
              {t('menu_contato')}
            </a>
            </li>



          {/* ... */}
        </ul>
        <div>
      <img 
        src={brazilFlag} 
        alt="Portuguese" 
        onClick={() => changeLanguage('ptbr')} 
        style={{ cursor: 'pointer', opacity: selectedLanguage === 'ptbr' ? 1 : 0.5, width:'30px', height:'30px', marginRight:'20px' }} 

      />
      <img 
        src={usaFlag} 
        alt="English" 
        onClick={() => changeLanguage('en')} 
        style={{ cursor: 'pointer', opacity: selectedLanguage === 'en' ? 1 : 0.5, width:'30px', height:'30px' }} 
      />
    </div>
        </div>
      </div>
      <div className={classes.section1}>
        <div className={classes.frame39}>
          <div className={classes.bRLAToken}>BRLA Token</div>
          <div className={classes.bRLAIsABRLPeggedStablecoinCrea}>
           {t('token_bottom')}
          </div>
          <div className={classes.frame40}>
            <div className={classes.frame2}>
              <div className={classes.openYourAccount}>
                <a href='/signup' style={{color:'white'}}>
                  {t('call_brla')}
                </a>
                </div>
            </div>
            <a href='https://docsend.com/view/6ufui53iazaks38x' target='_blank'>
            <div className={classes.frame1}>
              <div className={classes.checkTheWhitepaper}>{t('call_whitepaper')}</div>
            </div>
            </a>
          </div>
        </div>
        <div className={classes.frame41}>
          <div className={classes.tela_home}></div>
          <div className={classes.tela_swap}></div>
          <div className={classes.logocoin}></div>
        </div>
      </div>
      <div className={classes.section2}>
        <div className={classes.frame43}>
          <div className={classes.transparentAndSecure}>{t('transparent')}</div>
          <div className={classes.allTheBRLATokensAreStrictlyBac}>
           {t('transparent_bt')}
          </div>
          <a target='_blank' href='https://brladigital.notion.site/BRLA-Transparency-Page-238ba143aa2f4338902ee91ebe50298a?pvs=4'>
          <div className={classes.frame12}>
            <div className={classes.transparencyPage}>{t('transparent_page')}</div>
          </div>

          </a>

        </div>
        <div className={classes.frame42}>
          <div className={classes.image1}></div>
        </div>
      </div>
      <div className={classes.section3}>
        <div className={classes.frame44}>
          <div className={classes.accessTheBestOfCrypto}>{t('access_best')}</div>
          <div className={classes.withOver6MillionCryptoUsersInB}>
           {t('access_best_bt')}
          </div>
        </div>
        <div className={classes.frame45}>
          <div className={classes.image2}></div>
        </div>
      </div>
      <div className={classes.section4}>
        <div className={classes.frame47}>
          <div className={classes.bRLAAccount}>{t('brla_account')}</div>
          <div className={classes.createYourBRLAAccountAndStartU}>
            {t('create_brla')}
          </div>
          <div className={classes.frame13}>
            <div className={classes.openYourAccount2}>
                <a href='/signup' style={{color:'black'}}>
                  {t('call_brla')}
                </a>
                </div>
          </div>
        </div>
        <div className={classes.frame46}>
          <div className={classes.tela_home2}></div>
        </div>
      </div>
      <div className={classes.section6}>
        <div className={classes.frame14}>
          <div className={classes.contact}>{t('call_contact')}</div>
          <form className={classes.form} onSubmit={onSubmit}>
      <div className={classes.inputMobileWidth}>
        <input
          className={classes.divPlaceholder}
          type="text"
          placeholder={t('name')}
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </div>
      <div className={classes.inputMobileWidth2}>
        <input
          className={classes.divPlaceholder2}
          type="tel"
          placeholder={t('phone')}
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />
      </div>
      <div className={classes.inputMobileWidth3}>
        <input
          className={classes.divPlaceholder3}
          type="text"
          placeholder={t('company')}
          value={company}
          onChange={e => setCompany(e.target.value)}
        />
      </div>
      <div className={classes.inputMobileWidth4}>
        <input
          className={classes.divPlaceholder4}
          type="email"
          placeholder={t('email')}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <textarea
        className={classes.textarea}
        placeholder={t('message')}
        value={message}
        onChange={e => setMessage(e.target.value)}
      ></textarea>
      <button className={classes.button} type="submit">
        <div className={classes.submit}>{t('submit')}</div>
      </button>
    </form>
          <div className={classes.divHs_cos_wrapper_module_16619}>
            <div className={classes.followBRLAOnSocial}>{t('follow_social')}</div>
            <div className={classes.divSocialGrid}>
            <a href="https://twitter.com/BRLAdigital" target="_blank">
              <div className={classes.frame}>
                <FrameIcon className={classes.icon2} />
              </div>
              </a>
              <a href="https://www.linkedin.com/company/brla-digital/" target="_blank">
              <div className={classes.frame3}>
                <FrameIcon2 className={classes.icon3} />
              </div>
              </a>
            </div>
            <div className={classes.eMailInfoBrlaDigital}>E-mail: info@brla.digital</div>
            <div className={classes.phone55113777742}>{t('phone')}: +55 11 3777-0742</div>
            <div className={classes.addressRuaUrussui125Cj84SaoPau}>{t('address')}: Rua Urussui, 125 cj. 84 - São Paulo</div>
          </div>
          <div className={classes.digitalAssetsAreSubjectToANumb}>
           {t('disclaimer')}
          </div>
          <div className={classes._2023BRLADigital}>© 2023 BRLA Digital</div>
          <div className={classes.frame15}>
            <div className={classes.logocoin2}></div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default BRLATokenMobile;
