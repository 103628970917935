import { forwardRef, useState, useEffect, useRef } from 'react';
import { ENDPOINT, BRLA_CONTRACT_ADDRESSES, MANTEINENCETOKEN, USDC_CONTRACT_ADDRESSES, DEFAULTCOIN, USDT_CONTRACT_ADDRESSES, POLYGONURL} from '../../variaveis';
import resets from './_resets.module.css';
import classes from './Section.module.css';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import Web3 from 'web3';
import { Buffer } from "buffer/";
import { BRLAContractAbi, USDCContractAbi, USDTContractAbi } from '../../utils/abis';
import usaFlag from './usa_flag.png';
import brazilFlag from './brazil_flag.png';
import ListaCoins from '../../Components/ListaCoins';
import ListaCoinsRecebe from '../../Components/ListaCoinsRecebe';
import Checked from './checked.png';
import { useContext } from 'react';
import socketContext from '../Websocket/WebSocketContext';
import { useTranslation } from 'react-i18next';
import { isInstalled, sendPayment } from "@gemwallet/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
/* @figmaId 333:2369 */
const Transfer = forwardRef((props, ref) => {
  const [message, setMessage] = useState({ text: '', color: '' });
  const { t, i18n} = useTranslation();
  const {socket, connectWebSocket} = useContext(socketContext);
  const [spread, setSpread] = useState([]);
  Transfer.whyDidYouRender = true;


   


 
useEffect(() => {
  
  const fetchData = async () => {
    try {
      const response = await fetch(`${ENDPOINT}/on-chain/usdc-usdt-spread`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        // Agora você pode usar a variável 'data' para acessar os dados da resposta
        console.log(data);
        setSpread(data);
      } else if (response.status === 401) {
        // Redirecionar para a página /login se o status for 401
        navigate('/login');
      } else {
        throw new Error('Erro na requisição');
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, []);

  
  
  const [users, setUsers] = useState([]);
  const [fees, setFees] = useState([]);
  const [balance, setBalance] = useState([]);
  const [balanceUsd, setBalanceUsd] = useState([]);
  const [balanceUsdt, setBalanceUsdt] = useState([]);
  const [pixKeyType, setPixKeyType] = useState(users?.kyc?.documentType === 'CPF' ? 'CPF' : 'CNPJ');
  const [pixKey, setPixKey] = useState('');
  const [transferType, settransferType] = useState('PIX');
  const [pixKeyValid, setPixKeyValid] = useState(false);
  const [erroreq, seterroreq] = useState(false);
  const [currencyType, setcurrencyType] = useState('');
  const [CpfCnpj, setCpfCnpj] = useState('');
  const [Token, setToken] = useState(null);
  const [SocketMessage, setSocketMessage] = useState([]);
  const [ValidCpfCnpj, setValidCpfCnpj] = useState(false);
  const [marginLeftValue, setMarginLeftValue] = useState('100px');
  const [marginLeftValueP, setMarginLeftValueP] = useState('70px');
  const [codeoneActive, SetcodeoneActive] = useState(false);
  const [codetwoActive, SetcodetwoActive] = useState(false);
  const [codetreeActive, SetcodetreeActive] = useState(false);
  const [codefourActive, SetcodefourActive] = useState(false);
  const [codefiveActive, SetcodefiveActive] = useState(false);
  const [codesixActive, SetcodesixActive] = useState(false);
  useEffect(() => {
    // Obtem os usuários do localStorage
    const loadedFees = localStorage.getItem('feesData');
    if (loadedFees) {
      setFees(JSON.parse(loadedFees));
    }

   if (!socket || socket.readyState !== socket.OPEN) {
   connectWebSocket();
   }
 }, []);
  const isCPFValid = (strCPF) => {
    let Soma = 0;
    let Resto;
  
    if (strCPF === "00000000000") return false;
  
    for (let i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    }
  
    Resto = (Soma * 10) % 11;
  
    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;
  
    Soma = 0;
    for (let i = 1; i <= 10; i++) {
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    }
  
    Resto = (Soma * 10) % 11;
  
    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  
    return true;
  };

  function validaCNPJ (cnpj) {
    var b = [ 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2 ]
    var c = String(cnpj).replace(/[^\d]/g, '')
    
    if(c.length !== 14)
        return false

    if(/0{14}/.test(c))
        return false

    for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
    if(c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
    if(c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    return true
}


  const handlePixKeyChange = (event) => {
    setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior
    setPixKeyValid(false)
    const inputValue = event.target.value;
    let formattedValue = inputValue;
  
    

    setPixKey(inputValue);
  };

  const handleCpfCnpjChange = (event) => {
    setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior
    setValidCpfCnpj(false);
    const inputValue = event.target.value;
    let formattedValue = inputValue;
  
    

    setCpfCnpj(inputValue);
  }; 

  function isValidEmailFormat(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
  
  const handlePixKeyFormat = () => {
    let formattedValue = pixKey;
  
    // Check for CPF format (11 digits)
    if (isCPFValid(pixKey) && /^\d{11}$/.test(pixKey)) {
      formattedValue = `${pixKey.substring(0, 3)}.${pixKey.substring(3, 6)}.${pixKey.substring(6, 9)}-${pixKey.substring(9)}`;
      
    }
  
     // Check for CNPJ format (14 digits)
  if (validaCNPJ(pixKey) && /^\d{14}$/.test(pixKey)) {
    formattedValue = `${pixKey.substring(0, 2)}.${pixKey.substring(2, 5)}.${pixKey.substring(5, 8)}/${pixKey.substring(8, 12)}-${pixKey.substring(12)}`;
    
  }

  // Check for Cell Phone format (11 or 12 digits)
  if (/^\d{11,12}$/.test(pixKey) && !isCPFValid(pixKey) && !validaCNPJ(pixKey)) {
    formattedValue = `+55 (${pixKey.substring(0, 2)}) ${pixKey.substring(2, 7)}-${pixKey.substring(7)}`;
    
  }

  if (pixKey.replace(' ','').replace(/-/g, '').length === 32) {
   setPixKeyValid(true);
   setPixKeyType('Chave-aleatoria');
   GetPixInfo(pixKey.replace(' ',''));
   console.log("chave aleatoria")
  }

  if (pixKey.replace('+55','',).replace('(','').replace(')').replace(' ').replace('-','').length === 11) {
    setPixKeyValid(true)
    setPixKeyType('Celular');
    GetPixInfo(pixKey.replace('+55','',).replace('(','').replace(')').replace(' ').replace('-',''))
   }
  
  if (isValidEmailFormat(pixKey.replace(' ',''))) {
    setPixKeyValid(true)
    setPixKeyType('Email');
    GetPixInfo(pixKey.replace(' ',''))
  }


  if (isCPFValid(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''))) {
    setPixKeyValid(true);
    setPixKeyType('CPF');
    setValidCpfCnpj(true);
    GetPixInfo(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''));

  }

  if (validaCNPJ(pixKey.replace('.','').replace('-','').replace('.','').replace('/','').replace(' ',''))) {
    setPixKeyValid(true);
    setPixKeyType('CNPJ');
    setValidCpfCnpj(true);
    GetPixInfo(pixKey.replace('.','').replace('-','').replace('.','').replace('/','').replace(' ',''))
  }

    setPixKey(formattedValue);
  };



  const handleCpfCnpjFormat = () => {
    let formattedValue = CpfCnpj;
  
    // Check for CPF format (11 digits)
    if (isCPFValid(CpfCnpj) && /^\d{11}$/.test(CpfCnpj)) {
      formattedValue = `${CpfCnpj.substring(0, 3)}.${CpfCnpj.substring(3, 6)}.${CpfCnpj.substring(6, 9)}-${CpfCnpj.substring(9)}`;
      
    }
  
     // Check for CNPJ format (14 digits)
  if (validaCNPJ(CpfCnpj) && /^\d{14}$/.test(CpfCnpj)) {
    formattedValue = `${CpfCnpj.substring(0, 2)}.${CpfCnpj.substring(2, 5)}.${CpfCnpj.substring(5, 8)}/${CpfCnpj.substring(8, 12)}-${CpfCnpj.substring(12)}`;
    
  }





  if (isCPFValid(CpfCnpj.replace('.','').replace('-','').replace('.','').replace(' ',''))) {
    setValidCpfCnpj(true);
    setCpfCnpj(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''));

  }

  if (validaCNPJ(CpfCnpj.replace('.','').replace('-','').replace('.','').replace('/','').replace(' ',''))) {
    setValidCpfCnpj(true);
    setCpfCnpj(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''));
  }

    setCpfCnpj(formattedValue);
  };

  const handleWalletChange = (event) => {
    setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior
    setWalletValue(event.target.value);
    
  };


  useEffect(() => {
    // Obtem os usuários do localStorage
    const loadedUsers = localStorage.getItem('userData');
    if (loadedUsers) {
      const parsedUsers = JSON.parse(loadedUsers);
    setUsers(parsedUsers);
    setPixKeyType(parsedUsers.kyc.documentType === 'CPF' ? 'CPF' : 'CNPJ');
    }


    
    const fetchBalance = async () => {
    
   
      // Cria uma nova instância do web3 com o Infura
      const web3 = new Web3(new Web3.providers.HttpProvider(POLYGONURL));

      // Mapeia cada carteira para um objeto que inclui o saldo BRLA
      
          const BRLA_CONTRACT_ADDRESS = BRLA_CONTRACT_ADDRESSES[137];
          const USDC_CONTRACT_ADDRESS = USDC_CONTRACT_ADDRESSES[137];
          const USDT_CONTRACT_ADDRESS = USDT_CONTRACT_ADDRESSES[137];
          const contract = new web3.eth.Contract(BRLAContractAbi, BRLA_CONTRACT_ADDRESS);
          const contractUsd = new web3.eth.Contract(USDCContractAbi, USDC_CONTRACT_ADDRESS);
          const contractUsdt = new web3.eth.Contract(USDTContractAbi, USDT_CONTRACT_ADDRESS);
          const balance = await contract.methods.balanceOf(users.walletAddress).call();
          const balanceUsd = await contractUsd.methods.balanceOf(users.walletAddress).call();
          const balanceUsdt = await contractUsdt.methods.balanceOf(users.walletAddress).call();
          setBalance(balance)
          setBalanceUsdt(balanceUsdt);
          setBalanceUsd(balanceUsd);

      
      

      
  };

  if (users.walletAddress) {
    fetchBalance();
  }



  }, [users.walletAddress]);
  
  const handlePixKeyTypeChange = (type) => {
    setPixKeyType(type);
    setPixKey('')
  };

  const handleTransferTypeChange = (type) => {
    settransferType(type);
    setPixKey('');
  };

  const [isLoading, setIsLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [priceusd, setPriceusd] = useState('');
  const navigate = useNavigate();



  useEffect(() => {
    // Detectar sistema operacional
    const os = window.navigator.platform;
    const userAgent = window.navigator.userAgent;
    const linuxPattern = /Linux/;
    const windowsPattern = /Win/;
    const firefoxPattern = /Firefox/;
  
    // Se o sistema operacional for Linux ou o navegador for Firefox, defina marginLeft para '30px'
    if ( firefoxPattern.test(userAgent)) {
      setMarginLeftValue('20px');
      setMarginLeftValueP('-10px');
    }

    else if (linuxPattern.test(os)) {

      setMarginLeftValue('60px');
      setMarginLeftValueP('30px');
      
    }
  
    // Se o sistema operacional for Windows, defina marginLeft para '100px'
    else {
      setMarginLeftValue('100px');
      setMarginLeftValueP('70px');
    }
  }, []);
  

 

  const {
    register,
    handleSubmit,
    setFocus,
    control, // Adicione esta linha
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const [mintValue, setMintValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [ishighValue, setIshighvalue] = useState(false);
  const [recebeValue, setRecebeValue] = useState('');
  const [showPopup, setshowPopup]= useState(false);
  const [walletValue, setWalletValue] = useState('');
  const [isInvalidrecebe, setIsInvalidRecebe] = useState(false);
  const handleMintInputChange = (e) => {
    let value = e.target.value;
    setMessage('');
  setRecebeValue('');
  setIshighvalue(false);
    // Remove qualquer caractere que não seja número, ponto ou vírgula
    value = value.replace(/[^\d.,]/g, '');
  
    // Adiciona o "R$" apenas para exibição no input
    const displayValue = `${value}`;
  
    // Verifica se o valor é uma string vazia
    if (value === '') {
      setMintValue(displayValue);
     
      setIsInvalid(false);
      return;
    }

    if ((parseFloat(value)>50000 && selectedToken.abbreviation==='USD' && selectedTokenRecebe.abbreviation==='USDT' ) || (parseFloat(value)>50000 && selectedToken.abbreviation==='USDT' && selectedTokenRecebe.abbreviation==='USDC' )) {
      setIshighvalue(true)
    }
  
    // Remove o "R$" para realizar a validação numérica
    const numericValue = parseFloat(value.replace('R$', '').replace(',', '.'));
  
    // Verifica se o valor é um número com até duas casas decimais
    if (/^\d*\.?\d{0,2}$/.test(numericValue)) {
      setMintValue(displayValue);
      if (selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='BRL') {
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balance / Math.pow(10, 18)));
      if (displayValue<fees?.pixOutFee/100) {
        setRecebeValue(0);
      }
      else {
        setRecebeValue((parseFloat(displayValue)-parseFloat(fees?.pixOutFee)/100).toFixed(2));
      }}
      else if (selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='BRL') {
        setRecebeValue(SocketMessage?.data?.amountUsd/100);
        
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:"Polygon",
            coin:selectedTokenRecebe.abbreviation,
            usdToBrla: false,
            fixOutput: false
                        
                    }
      });
      }

      else if (selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='USD') {
        setRecebeValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:"Polygon",
            coin:DEFAULTCOIN,
            usdToBrla: true,
            fixOutput: false
                        
                    }
      });
        
      }

      else if (selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='USDT') {
        setRecebeValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:"Polygon",
            coin:'USDT',
            usdToBrla: true,
            fixOutput: false
                        
                    }
      });
        
      }

      else if (selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='BRL') {
        setRecebeValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:"Polygon",
            coin:'USDT',
            usdToBrla: false,
            fixOutput: false
                        
                    }
      });
        
      }

      else if (selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='USD') {
        setRecebeValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:"Polygon",
            coin:DEFAULTCOIN,
            usdToBrla: true,
            fixOutput: false
                        
                    }
      });
        
      }


      else if (selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='USDT') {
        setRecebeValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:"Polygon",
            coin:DEFAULTCOIN,
            usdToBrla: true,
            fixOutput: false
                        
                    }
      });
        
      }

      else if (selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='USD') {
        setRecebeValue(parseFloat(displayValue));
        setIsInvalid((numericValue) > (balanceUsd / Math.pow(10, 6)));
        
      }

      
      else if (selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD') {
        setRecebeValue((parseFloat(displayValue)*parseFloat(spread.spread)).toFixed(2));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsd / Math.pow(10, 6)));
        
      }

      else if (selectedTokenRecebe.abbreviation==='USDC' && selectedToken.abbreviation==='USDT') {
        setRecebeValue((parseFloat(displayValue)*parseFloat(spread.spread)).toFixed(2));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsdt / Math.pow(10, 6)));
        
      }

      else if (selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='BRL') {
        setRecebeValue(parseFloat(displayValue));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balance / Math.pow(10, 18)));
        
      }

      else if (selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USDT') {
        setRecebeValue(parseFloat(displayValue));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsdt / Math.pow(10, 6)));
        
      }

     
      
    }
  };
  




  const handleRecebeInputChange = (e) => {
    let value = e.target.value;
    setMessage('');
  setMintValue('');
  setIshighvalue(false);
    // Remove qualquer caractere que não seja número, ponto ou vírgula
    value = value.replace(/[^\d.,]/g, '');
  
    // Adiciona o "R$" apenas para exibição no input
    const displayValue = `${value}`;
  
    // Verifica se o valor é uma string vazia
    if (value === '') {
      setRecebeValue(displayValue);
      setIsInvalidRecebe(false);
      return;
    }

    if ( (parseFloat(value)>50000 && selectedToken.abbreviation==='USD' && selectedTokenRecebe.abbreviation==='USDT') || (parseFloat(value)>50000 && selectedToken.abbreviation==='USDT' && selectedTokenRecebe.abbreviation==='USDC') ) {
      setIshighvalue(true)
    }
  
    // Remove o "R$" para realizar a validação numérica
    const numericValue = parseFloat(value.replace('R$', '').replace(',', '.'));
  
    // Verifica se o valor é um número com até duas casas decimais
    if (/^\d*\.?\d{0,2}$/.test(numericValue)) {
      if ((selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='BRL')) {
        setMintValue(parseFloat(displayValue) + parseFloat(fees?.pixOutFee/100));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue+fees?.pixOutFee/100) > (balance / Math.pow(10, 18)));
      }
      else if ((selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='USD')) {
        setMintValue((parseFloat(displayValue)/(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:"Polygon",
            coin:DEFAULTCOIN,
            usdToBrla: true,
            fixOutput: true
                        
                    }
      });

      }
      else if ((selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='USD')) {
        setMintValue((parseFloat(displayValue)/(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:"Polygon",
            coin:DEFAULTCOIN,
            usdToBrla: true,
            fixOutput: true
                        
                    }
      });

      }

      else if ((selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='USDT')) {
        setMintValue((parseFloat(displayValue)/(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:"Polygon",
            coin:'USDT',
            usdToBrla: true,
            fixOutput: true
                        
                    }
      });

      }

      else if ((selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='BRL')) {
        setMintValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
console.log("A mensagem do socket é", SocketMessage);
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:"Polygon",
            coin:selectedTokenRecebe.abbreviation,
            usdToBrla: false,
            fixOutput: true
                        
                    }
      });
      }

      else if ((selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='BRL')) {
        setMintValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
console.log("A mensagem do socket é", SocketMessage);
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:"Polygon",
            coin:selectedTokenRecebe.abbreviation,
            usdToBrla: false,
            fixOutput: true
                        
                    }
      });
      }


      else if ((selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='USDT')) {
        setMintValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
console.log("A mensagem do socket é", SocketMessage);
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:"Polygon",
            coin:selectedTokenRecebe.abbreviation,
            usdToBrla: true,
            fixOutput: true
                        
                    }
      });
      }

      

      else if ((selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='USD')) {
        setMintValue(parseFloat(displayValue));
        setIsInvalid( (numericValue) > (balanceUsd / Math.pow(10, 6)));
      }

      else if ((selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='BRL')) {
        setMintValue(parseFloat(displayValue));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balance / Math.pow(10, 18)));
      }

      else if (selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD') {
        setMintValue((parseFloat(displayValue)/parseFloat(spread.spread)).toFixed(2));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsd / Math.pow(10, 6)));
        
      }

      else if (selectedTokenRecebe.abbreviation==='USDC' && selectedToken.abbreviation==='USDT') {
        setMintValue((parseFloat(displayValue)/parseFloat(spread.spread)).toFixed(2));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsd / Math.pow(10, 6)));
        
      }

      else if ((selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USDT')) {
        setMintValue(parseFloat(displayValue));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsdt / Math.pow(10, 6)));
      }
   
        
      }
    
     
    
      setRecebeValue(displayValue);
   
    
  };




  
  


  const isFormValid = () => {
    return mintValue !== '' && pixKey !== '' && !isInvalid;
  };
 

  const ClickUsa = (e) => {
    e.stopPropagation(); // Impede a propagação do evento de clique
    setcurrencyType('USA');
    setPixKey('');
  }

  const ClickBrazil = (e) => {
    e.stopPropagation(); // Impede a propagação do evento de clique
    setcurrencyType('Brazil');
    setPixKey('');
  }



  const [showListaCoins, setShowListaCoins] = useState(false);
  const [showListaCoinsRecebe, setShowListaCoinsRecebe] = useState(false);
  const ListaCoinsRef = useRef();
  const ListaCoinsRecebeRef = useRef();

  const buttonRefListaCoinst = useRef(null);
  const buttonRefListaCoinsRecebet = useRef(null);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (showListaCoins && ListaCoinsRef.current && !ListaCoinsRef.current.contains(event.target) && !buttonRefListaCoinst.current.contains(event.target)) {
      setShowListaCoins(false);
    
    }
  };

  document.addEventListener('click', handleClickOutside);

  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, [showListaCoins]);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (showListaCoinsRecebe && ListaCoinsRecebeRef.current && !ListaCoinsRecebeRef.current.contains(event.target) && !buttonRefListaCoinsRecebet.current.contains(event.target)) {
      setShowListaCoinsRecebe(false);
    }
  };

  document.addEventListener('click', handleClickOutside);

  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, [showListaCoinsRecebe]);


const [selectedToken, setSelectedToken] = useState({
  flag: 'currency-flag-brl',
  abbreviation: 'BRL'
});


const [selectedTokenRecebe, setSelectedTokenRecebe] = useState({
  flag: 'currency-flag-brl',
  abbreviation: 'BRL'
});



const currencyFlagClass = `currency-flag ${selectedToken.flag}`;
const currencyFlagRecebeClass = `currency-flag ${selectedTokenRecebe.flag}`;
const currencyFlagPIXClass = `currency-flag currency-flag-pix`;
const currencyFlagPolygonClass = `currency-flag currency-flag-polygon`;
const sendMessage = (messageObject) => {
  if (socket && socket.readyState === socket.OPEN) {
    console.log("Socket está recebendo :",messageObject)
      socket.send(JSON.stringify(messageObject));
  } else {
      console.error("socket is not open.");
  }
}

useEffect(() => {
  if (socket) {
    socket.onmessage = (event) => {
 
    const  data = JSON.parse(event.data)

      console.log("retorno socket", data);
      if (data?.success && data?.data?.quoteId) {
        setCompleted(true);
        setIsLoading(false);
      }
      else {
        setIsLoading(false);
        
      }
      if (!data?.success && data?.error && ((selectedTokenRecebe?.abbreviation==='USDC' && selectedToken?.abbreviation==='BRL') || (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USD') || (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USDT') || (selectedTokenRecebe?.abbreviation==='USDT' && selectedToken?.abbreviation==='BRL') || (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USDT') || (selectedTokenRecebe?.abbreviation==='USDC' && selectedToken?.abbreviation==='BRL') || (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USD') ) ) {
        setIsLoading(false);
    
      }

      if (data?.message === "user is already consuming the endpoint") {
        setMessage({
          text: "user is already consuming the endpoint",
          color: "red",
        });

      }
      setSocketMessage(data);
      setMessage({text: data.error, color:'red'});
     
    };
  }

  return () => {
    if (socket) {
      socket.onmessage = null;
    }
  };
}, [socket]);


const handleSendPayment = (tag) => {
      const payment = {
        amount: {value:mintValue, issuer: "rNWrQWYxqYj4Rz5RNrJxHeEMTwZrNtz9vy", currency:"BRL" }, // In drops (1 XRP)
        destination: "rNWrQWYxqYj4Rz5RNrJxHeEMTwZrNtz9vy",
        destinationTag:tag
      };
      sendPayment(payment).then((response) => {
        console.log("Transaction Hash: ", response.result?.hash);
        setIsLoading(false);
      });

};


const onSubmit = async (data) => {
  setIsLoading(true); // Definir como loading quando a requisição começar
  const cleanedPixKey = pixKeyType === 'CPF' || pixKeyType === 'CNPJ' || pixKeyType === 'Celular' ? pixKey.replace(/[-.()]/g, '').replace('R$ ', '').replace('+55', '').replace('/', '').replace(/ /g, '') : pixKey;
  const url = `${ENDPOINT}/dex/burn`;
  const body = {
      pixKey: cleanedPixKey,
      chain: 'XRP',
      taxId: pixKeyType==='CPF' || pixKeyType==='CNPJ' ? cleanedPixKey : CpfCnpj

  };

  try {
      const response = await fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          credentials: 'include', // Include credentials in the request
          body: JSON.stringify(body)
      });

      const responseData = await response.json();
      

      if (!response.ok) {
        toast.error(`Error: ${responseData.error}`)
        throw new Error(`Error: ${response.status}`);

      }
      else {
      console.log("Qualquer coisa")
      }

      handleSendPayment(responseData.destinationTag)
      return responseData;
  } catch (error) {
      console.error('Error making the request:', error);
  }
  setIsLoading(false);
}






const handleCoinSelected = (selectedCoin) => {
  if (selectedCoin?.abbreviation === 'USD') {
  }
  // Aqui, você pode adicionar outras condições para diferentes moedas, se necessário.
};

const getClass = () => {
 
   if (selectedTokenRecebe.abbreviation === 'BRL' && selectedToken.abbreviation === 'BRL' && !pixKeyValid) {
    return 'brl-brl';
    
  } else if (selectedTokenRecebe.abbreviation === DEFAULTCOIN && selectedToken.abbreviation === 'USD') {
    return 'brl-brl'
  }
  else if (selectedTokenRecebe.abbreviation === 'BRL' && selectedToken.abbreviation === 'USD' && pixKeyValid) {
    return 'brl-brl'
      }
   else {
    return 'brl-brl';
  }

}
const [pixInfo, setPixInfo] = useState(null);

async function GetPixInfo(pixKeyinput) {
  const response = await fetch(`${ENDPOINT}/pay-out/pix-info?pixKey=${pixKeyinput}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "MAINTENANCE-TOKEN":MANTEINENCETOKEN,
    },
    credentials:'include',
  });
  setIsLoading(false); // Definir como não loading quando a requisição terminar

  if (response.status === 200 || response.status === 201) {
    const responseData = await response.json();
    setPixInfo(responseData);

    
    // Não reabilite o botão
  } else {
    let responseData = {};
    if (response.headers.get('content-type').includes('application/json')) {
      responseData = await response.json();
    }
    // Trate erros aqui, caso necessário
   setMessage({text: responseData.error, color: 'red'})

}
}

function toTitleCase(str) {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}



  



  return (
    <>
    {showPopup  && (
 <div className="wallet-popup-overlay" style={{zIndex:5000}}>
 <div className="wallet-popup" style={{position: 'relative', zIndex:20000}}>
   


<p className="warning" style={{color:'black'}}>You are already connected. Please make sure to close any connections you have with us</p>


                 

 </div>
</div>
)}


    <form style={{zIndex:3000}} onSubmit={handleSubmit(onSubmit)}>
         <div style={{zIndex:700}} className="np-theme-personal" ref={ref}>
   
   

       
          <section className={`tw-flow__main ${getClass()}`} style={{ position: "absolute",width:'500px',  background:'white', padding:'20px', border:'1px solid white', borderRadius:'15px' }}>
           
          {!completed ? (
      
               
                    <div  className="row mx-auto">
                      <div  className="">
                        <div className="tabs">
                  
                          <div
                            className="tabs__panel-container"
                            style={{ overflow: "visible" }}
                          >
                            <div
                              className="tabs__slider"
                              style={{
                                width: "100%",
                                transform: "translateX(0px)"
                              }}
                            >
                              <div style={{ width: 32, display: "none" }} />
                              <div
                                className="tabs__panel"
                                role="tabpanel"
                                id="calculator-tabs-panel-0"
                                aria-labelledby="calculator-tabs-tab-0"
                                style={{ width: "100%", display: "block" }}
                              >
                                <div
                                  data-tracking-id="calculator"
                                  data-testid="calculator"
                                  className="tw-claculator"
                                >
                                 
                             

                                  {(selectedTokenRecebe.abbreviation === 'BRL' ) ? (
                                    <>
                                  <div style={{marginBottom:"0px"}} className="">
                                    <label htmlFor="tw-calculator-target">
                                    {t('chave_pix')}{" "}
                                    </label>
                                    <div  className="tw-money-input form-control" style={{display:'flex', height:'50px', border:'1px solid black', borderRadius:'15px', width:"450px"}}>
                                      <input
                                        className="input-transfer"
                                        id="tw-calculator-target"
                                        inputMode="text"
                                        placeholder={t('digite_sua_chave_pix')}
                                        autoComplete="off"
                                        onChange={handlePixKeyChange}
                                        onBlur={handlePixKeyFormat}
                                        value={pixKey}
                                        style={{width:"300px"}}
                                        
                                        
                                      />
                                      
                                      <div className="input-group-btn amount-currency-select-btn">
                                      <div style={{width:'100px', marginLeft:'16px'}} className="np-select btn-block btn-group">
                                          <button
                                           style={{minHeight:'70px', height:'70px'}}
                                            className="btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy"
                                            id="tw-calculator-target-select"
                                            role="combobox"
                                            aria-controls="tw-calculator-target-select-listbox"
                                            aria-expanded="false"
                                            aria-autocomplete="none"
                                            type="button"
                                            
                                            
                                          >
                                            <div style={{justifyContent:'center', alignContent:'center', verticalAlign:'center', marginBottom:'40px'}}  className="d-flex np-option-content">
                                              <div className="d-flex flex-column justify-content-center">
                                              <i style={{marginLeft:'-5px'}} className={currencyFlagPIXClass} />
                                              </div>
                                              <div className="d-flex flex-column justify-content-center">
                                                <span className="np-text-body-large text-ellipsis">
                                                <span style={{fontSize:'18px', marginRight:"25px"}} className="np-text-title-subsection tw-money-input__text">
                                                  PIX
                                                  </span>
                                                </span>
                                         
                                              </div>
                                              
                                            </div>
                                         
                                            <span
                                              className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="chevron-up-icon"
                                              style={{marginBottom:'40px'}}
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>


                                    {users?.twoFAActivated && (pixKeyType==='CPF' || pixKeyType==='CNPJ') &&
                            <>
                            
                 </>}

                                  </div>
                                  
                                  { !(pixKeyType==='CPF' || pixKeyType==='CNPJ') ? <div style={{marginBottom:"20px"}} className="">
                                  <label htmlFor="tw-calculator-target">
                                  {t('cpf_do_beneficiario')}{" "}
                                  </label>
                                  <div  className="tw-money-input form-control" style={{display:'flex', height:'50px', border:'1px solid black', borderRadius:'15px', width:"450px"}}>
                                    <input
                                      className="input-transfer"
                                      id="tw-calculator-target"
                                      inputMode="text"
                                      placeholder="Digite o CPF ou CNPJ"
                                      autoComplete="off"
                                      onChange={handleCpfCnpjChange}
                                      onBlur={handleCpfCnpjFormat}
                                      value={CpfCnpj}
                                      style={{width:"300px"}}
                                      
                                      
                                    />
                                    <div className="input-group-btn amount-currency-select-btn">
                                    <div style={{width:'100px', marginLeft:'16px'}} className="np-select btn-block btn-group">
                                        <button
                                         style={{minHeight:'70px', height:'70px'}}
                                          className="btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy"
                                          id="tw-calculator-target-select"
                                          role="combobox"
                                          aria-controls="tw-calculator-target-select-listbox"
                                          aria-expanded="false"
                                          aria-autocomplete="none"
                                          type="button"
                                          
                                          
                                        >
                                          <div style={{justifyContent:'center', alignContent:'center', verticalAlign:'center', marginBottom:'40px'}}  className="d-flex np-option-content">
                                            <div className="d-flex flex-column justify-content-center">
                                            <i style={{marginLeft:'-5px'}} className={currencyFlagPIXClass} />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                              <span className="np-text-body-large text-ellipsis">
                                              <span style={{fontSize:'18px', marginRight:"25px"}} className="np-text-title-subsection tw-money-input__text">
                                                PIX
                                                </span>
                                              </span>
                                       
                                            </div>
                                            
                                          </div>
                                       
                                          <span
                                            className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                            aria-hidden="true"
                                            role="presentation"
                                            data-testid="chevron-up-icon"
                                            style={{marginBottom:'40px'}}
                                          >
                                            <svg
                                              width={16}
                                              height={16}
                                              fill="currentColor"
                                              focusable="false"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                            </svg>
                                          </span>
                                        </button>


                                      </div>
                                    </div>
                                  </div>
                                 
                                </div> : <div></div>}


                                <div className="" style={{width:'450px'}}>
                                    <label htmlFor="tw-calculator-source">
                                    {t('voce_envia_exatamente')}
                                    </label>
                                    <div  className="tw-money-input form-control" style={{display:'flex', height:'50px', border:'1px solid black', borderRadius:'15px'}}>
                                      <div style={{display:'flex'}}>
                                      <input
                                        className="input-transfer"
                                        id="tw-calculator-source"
                                        inputMode="decimal"
                                        placeholder="1000.00"
                                        autoComplete="off"
                                        style={{border:'none'}}
                                        onChange={handleMintInputChange}
                                        value={
                                          isNaN(mintValue) ? "Carregando..." : mintValue
                                        }

                                        
                                        
                                      />
                                      <div  className="input-group-btn amount-currency-select-btn">
                                        <div style={{width:'100px', marginLeft:marginLeftValue}} className="np-select btn-block btn-group">
                                         <button
                                         style={{minHeight:'70px', height:'70px'}}
                                        
                                         
                                         
  ref={buttonRefListaCoinst}
  className={`btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy`}
  id="tw-calculator-source-select"
  
  role="combobox"
  aria-controls="tw-calculator-source-select-listbox"
  aria-expanded="false"
  aria-autocomplete="none"
  type="button"

>
                                    
                                            <div style={{justifyContent:'center', alignContent:'center', verticalAlign:'center', marginBottom:'40px'}}  className="d-flex np-option-content">
                                              <div  className="d-flex flex-column justify-content-center">
                                              <i style={{marginLeft: selectedToken.abbreviation === 'USDT' ? '-25px' : '-25px'}} className={currencyFlagClass} />
                                              </div>
                                              <div className="d-flex flex-column justify-content-center">
                                                <span className="np-text-body-large text-ellipsis">
                                                  <span style={{fontSize:'18px', marginRight:'25px', marginLeft:'-7px'}} className="np-text-title-subsection tw-money-input__text">
                                                  {selectedToken.abbreviation==='USD' ? 'USDC' : selectedToken.abbreviation}
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                            <span
                                              className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="chevron-up-icon"
                                              style={{marginBottom:'40px'}}
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                                className={`${showListaCoins ? 'rotate-icon' : ''}`}
                                                
                                              >
                                                <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                              </svg>
                                            </span>
                                            
                                          </button>
                                          
                                        </div>
                                        
                                      </div>
                                      </div>
                                      {showListaCoins && <ListaCoins ref={ListaCoinsRef} top='80px' left='200px'  />}

                                      <div
  style={{
    position: 'absolute',
    top: selectedToken.abbreviation==='BRL' && selectedTokenRecebe.abbreviation===DEFAULTCOIN ? '250px' : selectedTokenRecebe.abbreviation !== selectedToken.abbreviation &&
      !(selectedTokenRecebe.abbreviation === DEFAULTCOIN && selectedToken.abbreviation === 'USD')
      ? '250px'   
      : '180px',
    left: '200px',
    zIndex:2000
  }}
>
  {showListaCoinsRecebe && <ListaCoinsRecebe ref={ListaCoinsRecebeRef} />}
</div>


                                      
                                    </div>
                                    
                                  </div>


                                </>
                                  
                                  ) : (
<div></div>
) }
                                  { (selectedTokenRecebe.abbreviation==='BRL' && pixKey!=='' && pixKeyValid) ? (                                    <>

                                  <hr className="" style={{marginTop:'10px'}} />

                                  <div className="" style={{marginTop:'-25px'}}>
                                  <strong className="tw-review-details-card__title">
                                    <small className="ng-binding">
                                      {" "}
                                      {t('dados_do_beneficiario')}{" "}
                                    </small>
                                  </strong>
                               
                                </div>
                               
                                <p
                                  ng-repeat="detail in $ctrl.contact.display.details track by $index"
                                  className="tw-review-element  ng-scope"
                                  style={{}}
                                >
                                  <small style={{fontSize:'14px'}} className="tw-review-element__name ng-binding">
                                  {t('titular')}
                                  </small>
                                  <span style={{fontSize:'14px', marginTop:"-2px"}} className="tw-review-element__value text-primary ng-binding">
                                  {toTitleCase(pixInfo?.name || '')}
                                  </span>
                                </p>
                            
                               
                                {/* end ngRepeat: detail in $ctrl.contact.display.details track by $index */}
                               
                                {/* end ngRepeat: detail in $ctrl.contact.display.details track by $index */}
                                <p
                                  className="m-b-0 tw-review-element ng-hide"
                                  ng-show="$ctrl.deliveryMethod === 'LINK'"
                                  style={{}}
                                >
                                  <small style={{fontSize:'14px'}} className="tw-review-element__name ng-binding">
                                  {t('banco')}
                                  </small>
                                  <span style={{fontSize:'14px', marginTop:"-2px"}} className="tw-review-element__value text-primary ng-binding">
                                  {toTitleCase(pixInfo?.bankName || '')}
                                  </span>
                                </p>
                                </>) : (
                                  <div></div>
                                )}
                                
                                  <div className="m-t-3 tw-calculator-notices"  >
                                    <div
                                      role="alert"
                                      className="alert d-flex alert-neutral"
                                      style={{height:'20px', marginTop:"-20px"}}
                                    >
                                      <div
                                        className="alert__content d-flex flex-grow-1 desktop"
                                        data-testid="desktop"
                                      >
                                        <span
                                          data-testid="status-icon"
                                          className="status-circle status-circle-lg neutral"
                                          style={{display:'flex',width:'30px', height:'30px', alignItems:'center', justifyContent:'center', alignContent:'center', verticalAlign:"center", marginTop:'-15px'}}
                                        >
                                          <span
                                            className="tw-icon tw-icon-info status-icon light"
                                            aria-hidden="true"
                                            role="presentation"
                                            data-testid="info-icon"
                                            
                                          >
                                            <svg
                                              width={16}
                                              height={16}
                                              fill="currentColor"
                                              focusable="false"
                                              viewBox="0 0 24 24"
                                              
                                            >
                                              <path d="M11.143 9.857h1.714v9.857h-1.714z" />
                                              <circle cx={12} cy={6} r="1.714" />
                                            </svg>
                                          </span>
                                        </span>


                                        <div style={{height:'10px', fontSize:'14px', lineHeight:"6px"}} className="alert__message">
                                          <span  className="">
                                            <span >
                                            {t('termos')}
                                            </span>


                                            <a
                                            href="https://brla.digital/terms-and-services-pt.pdf"
                                            target="_blank"
                                            className="np-link  d-inline-flex m-t-1"
                                            rel="noreferrer"
                                            style={{fontSize:'14px'}}
                                          >
                                            {t('saiba_mais')}
                                            <span
                                              className="tw-icon tw-icon-navigate-away "
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="navigate-away-icon"
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                                style={{width:'10px', height:"10px"}}
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M12.857 1.287h8.571a.86.86 0 0 1 .857.857v8.572h-1.714V4.2l-9.428 9.429-1.2-1.2 9.428-9.429h-6.514V1.287ZM3.429 20.998H18v-6h1.714v6.857a.86.86 0 0 1-.857.858H2.572a.86.86 0 0 1-.858-.858V4.713a.86.86 0 0 1 .857-.858h6V5.57H3.43v15.428Z"
                                                />
                                              </svg>
                                            </span>
                                          </a>

                                          </span>
                                
                                        </div>
                                       
                                      </div>
                                    </div>
                                  </div>
                                  {ishighValue && <p style={{display:'flex', color: 'red', fontSize:'12px', textAlign:'center', justifyContent:'center', alignItems:"center" }}>{t('valor_menor')}</p>}
 <p style={{ color: 'red', fontSize:'12px', textAlign:'center' }}>{message.text}</p>

                                  <div className="d-flex m-t-2">
                       
                                    <button
                                      className="btn btn-md np-btn np-btn-md btn-block np-btn-block btn-positive btn-priority-1 m-t-0"
                                      data-testid="continue-button"
                                      type="submit"
                                      disabled={(  ( pixKey==='' || !pixKeyValid || !ValidCpfCnpj )) || isLoading   }
                                    >
                                      {isLoading ? t('carregando') + "..." : t('confirm_transfer')}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div style={{ width: 32, display: "none" }} />
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>) : (
                      <div style={{display:"flex", justifyContent:"center", paddingTop:"80px", paddingBottom:"100px"}} className="row mx-auto">
                      
                     
                  
                         
                         
                             
                             
                               
                                 
  <img style={{width:'100px', height:'100px'}} src={Checked} />
  
  <p style={{marginTop:"20px", marginBottom:'10px'}}>{t('complete')}</p>
</div>






                    )}
                 
               
            
          </section>
        </div>
     
        <ToastContainer />
        </form>    
    </>
  );
});
export default Transfer
