
import resets from './_resets.module.css';
import classes from './Section.module.css';
import { forwardRef } from 'react';
const TransactionDetailsUSDBRL = forwardRef((props, ref) => {



  function formatDate(dateString) {
    // Cria um objeto Date a partir da string
    let date = new Date(dateString);
  
    // Ajusta para o fuso horário local
    date = new Date(date.getTime() );
  
    // Extrai os componentes da data
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
  
    // Mapeia os números dos meses para os nomes dos meses
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthName = monthNames[month];
  
    // Retorna a data formatada
    return `${monthName} ${day}, ${year}`;
  }



  function formatTime(dateString) {
    // Cria um objeto Date a partir da string
    let date = new Date(dateString);
  
    // Ajusta para o fuso horário local
    date = new Date(date.getTime() );
  
    // Extrai as componentes de hora e minuto
    let hour = date.getHours();
    const minute = date.getMinutes();
  
    // Converte a hora para o formato 12 horas
    let period = 'AM';
    if (hour >= 12) {
      period = 'PM';
      if (hour > 12) hour -= 12;
    } else if (hour === 0) {
      hour = 12;
    }
  
    // Retorna a hora formatada
    return `${hour}:${minute < 10 ? '0' : ''}${minute} ${period}`;
  }


  return (
    <div ref={ref} style={{position:'absolute', zIndex:1000, marginLeft: 'auto', marginRight:'auto', left:0, right:0}} className={`${resets.storybrainResets} ${classes.root}`}>
      <div className={classes.frame16}>
        <div className={classes.frame2}>
          <div className={classes.transferReceipt}>Detalhes da transação</div>
          <div className={classes.frame20}>
            <div className={classes.conversionTime}>Data da transação:</div>
            <div className={classes.jul32023345PM}>{formatDate(props.transaction.updatedAt)} {formatTime(props.transaction.updatedAt)}</div>
          </div>
          <div className={classes.frame24}>
            <div className={classes.uSDTReceivedAmout}>Valor pago de USD:</div>
            <div className={classes._736}>$ {props.transaction ? parseFloat(props.transaction.usdAmount/100).toFixed(2) : 'Pendente'}</div>
          </div>
          <div className={classes.frame23}>
            <div className={classes.uSDTBRLSpotPrice}>Preço Spot USD/BRL:</div>
            <div className={classes._47983}>{props.transaction ? (parseFloat(props.transaction.basePrice)).toFixed(4) : 'Pendente'}</div>
          </div>
          <div className={classes.frame21}>
            <div className={classes.totalFees}>Total de taxas:</div>
            <div className={classes._0736USDT}>{(parseFloat(props.transaction.baseFee)+parseFloat(props.transaction.gassFee)).toFixed(5)} BRL</div>
          </div>
          <div className={classes.frame22}>
            <div className={classes.bRLReceivedAmount}>Valor recebido líquido</div>
            <div className={classes._3528BRL}>R$ {(props.transaction.brlaAmount/100).toFixed(2)}</div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default TransactionDetailsUSDBRL;
