import React, { useState } from 'react';

import resets from "../Home/_resets.module.css"
import classes from "./BRLADigitalAbout.module.css"
import { FrameIcon2 } from "../Home/FrameIcon2.js"
import { FrameIcon } from "../Home/FrameIcon.js"
import Teresa from './images/luizSemFundo1.jpg'
import Mohammed from './images/matheusSemFundo1.jpg'
import Super from './images/leandroSemFundo1.jpg'
import Lucas from './images/lucasSemFundo1.jpg'
import Hector from './images/hectorRemovebgPreview11.jpg'
import Caio from './images/caioSemFundo1.jpg'
import Eric from './images/image3.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './css/styles.css';
import { useTranslation } from 'react-i18next';
import brazilFlag from '../Home/images/brazil.png'; // import your flag images
import usaFlag from '../Home/images/usa.png';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

declare var grecaptcha: any;
/* @figmaId 52:256 */
function About() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const { t, i18n} = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };

  const [visibleMemberId, setVisibleMemberId] = useState(null);

  const toggleDescription = (id, e) => {
    e.preventDefault();
    if (visibleMemberId === id) {
      setVisibleMemberId(null);
    } else {
      setVisibleMemberId(id);
    }
  };

  const closeProfile = (e) => {
    e.preventDefault();
    setVisibleMemberId(null);
  };


  function onSubmit(e) {
    e.preventDefault();
  
    grecaptcha.ready(function() {
      grecaptcha.execute('6LdIKIQmAAAAABNZn-t5ilym4wSfgsAkLuzBtn00', {action: 'submit'}).then(function(token) {
        const data = {
          name: name,
          email: email,
          phone: phone,
          company: company,
          message: message,
          captcha: token
        };
  
        fetch('https://api.brla.digital:5567/v1/contact-us', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
        .then(response => {
          if (response.ok) {
            toast.success('Message sent successfully. We will respond soon.', {
              autoClose: 6000
            });
            setTimeout(() => window.location.reload(), 7000);
          } else {
            throw new Error('Failed to send message');
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error('Failed to send message. Please try again.', {
            autoClose: 6000
          });
          setTimeout(() => window.location.reload(), 7000);
        });
      });
    });
  }


  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <div className={classes.header}>
        <div className={classes.logo}></div>
        <div className={classes.ulMainNavigation}>
  <a href='/'>
    <div className={classes.home}>Home</div>
  </a>
  <div className={classes.dropdown}>
    <div className={classes.about}>{t('menu_about')}</div>
    <div className={classes.dropdownContent}>
      <a href='/about-brla-token'>BRLA Token</a>
      <a href='/about-brla-digital'>BRLA Digital</a>
    </div>
  </div>
  <a href='/login'>
    <div className={classes.login}>Login</div>
  </a>
  <a href='signup'>
    <div className={classes.sIGNUP}>{t('menu_sign')}</div>
  </a>
  <a href='https://api-documentation.brla.digital/#general-info' target='_blank'>
    <div className={classes.aPIREFERENCE}>{t('menu_api')}</div>
  </a>
  <a href='#contact'>
    <div className={classes.cONTACT}>{t('menu_contato')}</div>
  </a>
  <div>
      <img 
        src={brazilFlag} 
        alt="Portuguese" 
        onClick={() => changeLanguage('ptbr')} 
        style={{ cursor: 'pointer', opacity: selectedLanguage === 'ptbr' ? 1 : 0.5, width:'30px', height:'30px', marginRight:'20px' }} 

      />
      <img 
        src={usaFlag} 
        alt="English" 
        onClick={() => changeLanguage('en')} 
        style={{ cursor: 'pointer', opacity: selectedLanguage === 'en' ? 1 : 0.5, width:'30px', height:'30px' }} 
      />
    </div>



</div>


      </div>
      <div className={classes.section}>
        <div className={classes.ourVisionIsToBeTheLargestInfra}>
          {t('vision')}
        </div>
      </div>
      <div className={classes.section2}>
        <div className={classes.frame35}>
          <div className={classes.ourMissionIsToBringProsperityT}>
            {t('mission')}
          </div>
          <div className={classes.bRLADigitalIsATechnologyCompan}>
            {t('mission_bt')}
          </div>
        </div>
      </div>
      <div className={classes.section3}>
        <div className={classes.heading2Team}>{t('team')}</div>
        <div className={classes.divRow}>

          <a onClick={(e) => toggleDescription(214, e)}
                            href="#team-member-214"
                            className="team-member-card post-214 people type-people status-publish has-post-thumbnail hentry people_area-socios-diretores">
          <div className={classes.link}>
            <div className={classes.luizSemFundo1}>


            </div>
            <div className={classes.heading3LuizCasteloBranco}>
              Luiz Castelo Branco
            </div>
            <div className={classes.heading5}>
              <div className={classes.cTO}>CTO</div>
            </div>
          </div>
          </a>
          <div
                            className="team-member-profile equipe-mobile"
                            id="team-member-214"
                            style={{ display: visibleMemberId === 214 ? 'block' : 'none', overflowX: 'hidden' }}
                          >
                            <div style={{overflow: 'hidden'}} className="team-member-profile-holder">
                            
                              <a onClick={(e) => closeProfile(e)} href="#" className="team-member-profile-close">
                                ╳
                              </a>
                              <div  className="container">
                                <div style={{width:'3000px'}}  className="row">
                                  <div style={{width:'100%'}} className="col-sm-5 col-md-4">
                                  
                                     
                                      <img
                                        width={300}
                                        height={300}
                                        src={Teresa}
                                        style={{marginTop:'-180px'}}  
                                        
                                      />
                                      
                                   
                                    <div style={{display:'block'}} className="col-sm-7 col-md-8">
                                    <h3>Luiz Castelo Branco</h3>
                                    <h5 style={{marginBottom:'10px'}}>CTO</h5>
                                    <a
                                      href="https://www.linkedin.com/in/luiz-castelo-branco-57369372/"
                                      target="_blank"
                                      className="team-member-linkedin-link"
                                    >
                                      <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                                      
                                    </a>
                                   <p>{t('luizcastelo')}</p>
                                  </div>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </div>

          <a onClick={(e) => toggleDescription(216, e)}
                            href="#team-member-216"
                            className="team-member-card post-216 people type-people status-publish has-post-thumbnail hentry people_area-socios-diretores">
            <div className={classes.link2}>
              <div className={classes.matheusSemFundo1}></div>
              <div className={classes.heading3MatheusMoura}>Matheus Moura</div>
              <div className={classes.heading52}>
                <div className={classes.coCEO}>CEO</div>
              </div>
            </div>
            </a>

            <div
                            className="team-member-profile equipe-mobile"
                            id="team-member-216"
                            style={{ display: visibleMemberId === 216 ? 'block' : 'none' }}
                          >
                            <div style={{overflow: 'hidden'}} className="team-member-profile-holder">
                            
                              <a onClick={(e) => closeProfile(e)} href="#" className="team-member-profile-close">
                                ╳
                              </a>
                              <div  className="container">
                                <div style={{width:'3000px'}}  className="row">
                                  <div style={{width:'100%'}} className="col-sm-5 col-md-4">
                                  
                                     
                                      <img
                                        width={300}
                                        height={300}
                                        src={Mohammed}
                                        style={{marginTop:'-180px'}}  
                                        
                                      />
                                      
                                   
                                    <div style={{display:'block'}} className="col-sm-7 col-md-8">
                                    <h3>Matheus Moura</h3>
                                    <h5 style={{marginBottom:'10px'}}>CEO</h5>
                                    <a
                                      href="https://www.linkedin.com/in/leandro-noel-542b1434/"
                                      target="_blank"
                                      className="team-member-linkedin-link"
                                    >
                                      <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                                      
                                    </a>
                                   <p>{t('matheusmoura')}</p>
                                  </div>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                          <a
                            href="#team-member-320"
                            className="team-member-card post-300 people type-people status-publish has-post-thumbnail hentry people_area-socios-diretores"
                            onClick={(e) => toggleDescription(320, e)}
                          >

          <div className={classes.link7}>
            <div className={classes.heading3EricBastos}>Eric Bastos</div>
            <div className={classes.heading56}>
              <div className={classes.techManager}>Tech Manager</div>
            </div>
            <div className={classes.image3}></div>
          </div>

          </a>

          <div
                            className="team-member-profile equipe-mobile"
                            id="team-member-320"
                            style={{ display: visibleMemberId === 320 ? 'block' : 'none' }}
                          >
                            <div style={{overflow: 'hidden'}} className="team-member-profile-holder">
                            
                              <a onClick={(e) => closeProfile(e)} href="#" className="team-member-profile-close">
                                ╳
                              </a>
                              <div  className="container">
                                <div style={{width:'3000px'}}  className="row">
                                  <div style={{width:'100%'}} className="col-sm-5 col-md-4">
                                  
                                     
                                      <img
                                        width={270}
                                        height={270}
                                        src={Eric}
                                        style={{marginTop:'-180px'}}  
                                        
                                      />
                                      
                                   
                                    <div style={{display:'block'}} className="col-sm-7 col-md-8">
                                    <h3>Eric Bastos</h3>
                                    <h5 style={{marginBottom:'10px'}}>Tech Manager</h5>
                                    <a
                                      href="https://www.linkedin.com/in/eric-bastos/"
                                      target="_blank"
                                      className="team-member-linkedin-link"
                                    >
                                      <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                                      
                                    </a>
                                   <p>   {t('ericbastos')}
</p>
                                  </div>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </div>

                          <a onClick={(e) => toggleDescription(301, e)}
                            href="#team-member-301"
                            className="team-member-card post-301 people type-people status-publish has-post-thumbnail hentry people_area-socios-diretores">
          <div className={classes.link3}>
            <div className={classes.leandroSemFundo1}></div>
            <div className={classes.heading3LeandroNoel}>Leandro Noel</div>
            <div className={classes.heading53}>
              <div className={classes.coCEO2}>CSO</div>
            </div>
          </div>
</a>
          <div
                            className="team-member-profile equipe-mobile"
                            id="team-member-216"
                            style={{ display: visibleMemberId === 301 ? 'block' : 'none' }}
                          >
                            <div style={{overflow: 'hidden'}} className="team-member-profile-holder">
                            
                              <a onClick={(e) => closeProfile(e)} href="#" className="team-member-profile-close">
                                ╳
                              </a>
                              <div  className="container">
                                <div style={{width:'3000px'}}  className="row">
                                  <div style={{width:'100%'}} className="col-sm-5 col-md-4">
                                  
                                     
                                      <img
                                        width={300}
                                        height={300}
                                        src={Super}
                                        style={{marginTop:'-180px'}}  
                                        
                                      />
                                      
                                   
                                    <div style={{display:'block'}} className="col-sm-7 col-md-8">
                                    <h3>Leandro Noel</h3>
                                    <h5 style={{marginBottom:'10px'}}>CSO</h5>
                                    <a
                                      href="https://www.linkedin.com/in/leandro-noel-542b1434/"
                                      target="_blank"
                                      className="team-member-linkedin-link"
                                    >
                                      <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                                      
                                    </a>
                                   <p>Leandro is CSO of BRLA Digital.  He was Head of Research at Ada Capital. He previously joined the renewable energy startup, Athon Energia, helping the company reach a valuation of approximately R$ 500 million by working on various fronts. With experience in corporate restructuring, Leandro has worked with Pátria and Alvarez & Marsal on M&A, financial management, governance, and debt restructuring. Leandro began his career as an investment analyst in the Private Equity division of Pátria Investimentos. He graduated with a degree in Civil-Aeronautical Engineering from the Aeronautics Institute of Technology (ITA).</p>
                                  </div>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </div>



        </div>

        <div className={classes.divRow2}>


        <a onClick={(e) => toggleDescription(300, e)}
                            href="#team-member-300"
                            className="team-member-card post-301 people type-people status-publish has-post-thumbnail hentry people_area-socios-diretores">

          <div className={classes.link4}>
            <div className={classes.lucasSemFundo1}></div>
            <div className={classes.heading3LucasGiorgio}>Lucas Giorgio</div>
            <div className={classes.heading54}>
              <div className={classes.cBDO}>CBDO</div>
            </div>
          </div>
          </a>

          <div
                            className="team-member-profile equipe-mobile"
                            id="team-member-300"
                            style={{ display: visibleMemberId === 300 ? 'block' : 'none' }}
                          >
                            <div style={{overflow: 'hidden'}} className="team-member-profile-holder">
                            
                              <a onClick={(e) => closeProfile(e)} href="#" className="team-member-profile-close">
                                ╳
                              </a>
                              <div  className="container">
                                <div style={{width:'3000px'}}  className="row">
                                  <div style={{width:'100%'}} className="col-sm-5 col-md-4">
                                  
                                     
                                      <img
                                        width={300}
                                        height={300}
                                        src={Lucas}
                                        style={{marginTop:'-180px'}}  
                                        
                                      />
                                      
                                   
                                    <div style={{display:'block'}} className="col-sm-7 col-md-8">
                                    <h3>Lucas Giorgio</h3>
                                    <h5 style={{marginBottom:'10px'}}>CBDO</h5>
                                    <a
                                      href="https://www.linkedin.com/in/lucas-giorgio-rostworowski-70b96b22/"
                                      target="_blank"
                                      className="team-member-linkedin-link"
                                    >
                                      <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                                      
                                    </a>
                                   <p>{t('lucasgiorgio')}</p>
                                  </div>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </div>



                          <a onClick={(e) => toggleDescription(218, e)}
                            href="#team-member-218"
                            className="team-member-card post-218 people type-people status-publish has-post-thumbnail hentry people_area-socios-diretores">     


          <div className={classes.link5}>
            <div className={classes.hectorRemovebgPreview11}></div>
            <div className={classes.heading3HectorFardin}>Hector Fardin</div>
            <div className={classes.heading55}>
              <div className={classes.cOO}>COO</div>
            </div>
          </div>
</a>
          <div
                            className="team-member-profile equipe-mobile"
                            id="team-member-218"
                            style={{ display: visibleMemberId === 218 ? 'block' : 'none' }}
                          >
                            <div style={{overflow: 'hidden'}} className="team-member-profile-holder">
                            
                              <a onClick={(e) => closeProfile(e)} href="#" className="team-member-profile-close">
                                ╳
                              </a>
                              <div  className="container">
                                <div style={{width:'3000px'}}  className="row">
                                  <div style={{width:'100%'}} className="col-sm-5 col-md-4">
                                  
                                     
                                      <img
                                        width={300}
                                        height={300}
                                        src={Hector}
                                        style={{marginTop:'-180px'}}  
                                        
                                      />
                                      
                                   
                                    <div style={{display:'block'}} className="col-sm-7 col-md-8">
                                    <h3>Hector Fardin</h3>
                                    <h5 style={{marginBottom:'10px'}}>COO</h5>
                                    <a
                                      href="https://www.linkedin.com/in/hector-selvatice-fardin-8419a357/"
                                      target="_blank"
                                      className="team-member-linkedin-link"
                                    >
                                      <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                                      
                                    </a>
                                   <p>{t('hectorfardin')}</p>
                                  </div>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </div>

                          <a
                            href="#team-member-302"
                            className="team-member-card post-302 people type-people status-publish has-post-thumbnail hentry people_area-socios-diretores"
                            onClick={(e) => toggleDescription(302, e)}
                          >

          <div className={classes.link6}>
            <div className={classes.caioSemFundo1}></div>
            <div className={classes.heading3CaioElYanes}>Caio El Yanes</div>
            <div className={classes.heading6}>
              <div className={classes.productManager}>Product Manager</div>
            </div>
          </div>
</a>

          <div
                            className="team-member-profile equipe-mobile"
                            id="team-member-302"
                            style={{ display: visibleMemberId === 302 ? 'block' : 'none' }}
                          >
                            <div style={{overflow: 'hidden'}} className="team-member-profile-holder">
                            
                              <a onClick={(e) => closeProfile(e)} href="#" className="team-member-profile-close">
                                ╳
                              </a>
                              <div  className="container">
                                <div style={{width:'3000px'}}  className="row">
                                  <div style={{width:'100%'}} className="col-sm-5 col-md-4">
                                  
                                     
                                      <img
                                        width={300}
                                        height={300}
                                        src={Caio}
                                        style={{marginTop:'-180px'}}  
                                        
                                      />
                                      
                                   
                                    <div style={{display:'block'}} className="col-sm-7 col-md-8">
                                    <h3>Caio El Yanes</h3>
                                    <h5 style={{marginBottom:'10px'}}>Product Manager</h5>
                                    <a
                                      href="https://www.linkedin.com/in/caio-el-yanes/"
                                      target="_blank"
                                      className="team-member-linkedin-link"
                                    >
                                      <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                                      
                                    </a>
                                   <p>{t('caioelyanes')}
</p>
                                  </div>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </div>

        </div>
        {/*<div className={classes.divRow3}>

        <a
                            href="#team-member-320"
                            className="team-member-card post-300 people type-people status-publish has-post-thumbnail hentry people_area-socios-diretores"
                            onClick={(e) => toggleDescription(320, e)}
                          >

          <div className={classes.link7}>
            <div className={classes.heading3EricBastos}>Eric Bastos</div>
            <div className={classes.heading56}>
              <div className={classes.techManager}>Tech Manager</div>
            </div>
            <div className={classes.image3}></div>
          </div>

          </a>

          <div
                            className="team-member-profile equipe-mobile"
                            id="team-member-320"
                            style={{ display: visibleMemberId === 320 ? 'block' : 'none' }}
                          >
                            <div style={{overflow: 'hidden'}} className="team-member-profile-holder">
                            
                              <a onClick={(e) => closeProfile(e)} href="#" className="team-member-profile-close">
                                ╳
                              </a>
                              <div  className="container">
                                <div style={{width:'3000px'}}  className="row">
                                  <div style={{width:'100%'}} className="col-sm-5 col-md-4">
                                  
                                     
                                      <img
                                        width={270}
                                        height={270}
                                        src={Eric}
                                        style={{marginTop:'-180px'}}  
                                        
                                      />
                                      
                                   
                                    <div style={{display:'block'}} className="col-sm-7 col-md-8">
                                    <h3>Eric Bastos</h3>
                                    <h5 style={{marginBottom:'10px'}}>Tech Manager</h5>
                                    <a
                                      href="https://www.linkedin.com/in/eric-bastos/"
                                      target="_blank"
                                      className="team-member-linkedin-link"
                                    >
                                      <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                                      
                                    </a>
                                   <p>   Éric is part of the Founding Team and a Tech Lead at BRLA Digital.  Éric was a Software Engineer at Ada Capital, primarily working on quantitative investment strategies. Prior to joining Ada, Éric worked in the Middle Office at RB Capital. Éric holds a degree in Computer Engineering from the Aeronautics Institute of Technology (ITA).
</p>
                                  </div>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>*/}
      </div>
      <div id="contact" className={classes.footerFooter}>
        <div className={classes.contact}>{t('call_contact')}</div>
        <div className={classes.divRow4}>
        <form className={classes.form} onSubmit={onSubmit}>
      <div className={classes.frame32}>
        <input
          className={classes.inputMobileWidth}
          type="text"
          name="name"
          placeholder={t('name')}
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <input
          className={classes.inputMobileWidth2}
          type="email"
          name="email"
          placeholder={t('email')}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <div className={classes.frame33}>
        <input
          className={classes.inputMobileWidth3}
          type="tel"
          name="phone"
          placeholder={t('phone')}
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />
        <input
          className={classes.inputMobileWidth4}
          type="text"
          name="company"
          placeholder={t('company')}
          value={company}
          onChange={e => setCompany(e.target.value)}
        />
      </div>
      <textarea
        className={classes.textarea}
        name="message"
        placeholder={t('message')}
        value={message}
        onChange={e => setMessage(e.target.value)}
      ></textarea>
      <div className={classes.frame34}>
        <button className={classes.button} style={{width:'90px', color:'white', textTransform:'uppercase', fontSize:"16px"}} type="submit">
          {t('submit')}
        </button>
      </div>
    </form>
          <div className={classes.divHs_cos_wrapper_module_16619}>
            <div className={classes.followBRLAOnSocial}>
             {t('follow_social')}
            </div>
            <div className={classes.divSocialGrid}>
              <div className={classes.frame}>
                <a style={{display:'block'}} href="https://twitter.com/BRLAdigital" target="_blank">
                <FrameIcon className={classes.icon} />
                </a>
              </div>
              <div className={classes.frame2}>
                <a href="https://www.linkedin.com/company/brla-digital/" target="_blank">
                <FrameIcon2 className={classes.icon2} />
                </a>
              </div>
            </div>
            <div className={classes.eMailInfoBrlaDigital}>
              E-mail: info@brla.digital
            </div>
            <div className={classes.phone55113777742}>
              {t('phone')}: +55 11 3777-0742
            </div>
            <div className={classes.addressRuaUrussui125Cj84SaoPau}>
             {t('address')}: Rua Urussui, 125 cj. 84 - São Paulo
            </div>
          </div>
        </div>
        <div className={classes.divRow5}>
          <div className={classes.frame31}>
            <div className={classes.logocoin}></div>
          </div>
          <div className={classes.footer}>
            <div className={classes.digitalAssetsAreSubjectToANumb}>
              {t('disclaimer')}
            </div>
            <div className={classes._2023BRLADigital}>© 2023 BRLA Digital</div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
};
export default About
